var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { motion } from "framer-motion";
import { useState } from "react";
import { FaArrowCircleLeft } from "react-icons/fa";
import { FaCheck } from "react-icons/fa";
var DefibControls = function (_a) {
    var onGoBack = _a.onGoBack;
    var _b = useState(120), joules = _b[0], setJoules = _b[1];
    var _c = useState(false), padsApplied = _c[0], setPadsApplied = _c[1];
    var _d = useState(false), charged = _d[0], setCharged = _d[1];
    var handlePadsApply = function () {
        setPadsApplied(true);
        setCharged(false); // reset charge if pads are reapplied
    };
    var handleCharge = function () {
        if (padsApplied) {
            setCharged(true);
        }
    };
    var handleShock = function () {
        if (charged) {
            console.log("Shock delivered with ".concat(joules, " Joules"));
            setCharged(false); // reset after shock
        }
    };
    return (_jsxs(motion.div, __assign({ className: "h-auto w-full flex flex-col gap-2 content-center bg-mirno-dark inline", initial: { opacity: 0 }, animate: { opacity: 1 }, exit: { opacity: 0 }, transition: { duration: 0.3 } }, { children: [_jsxs("button", __assign({ className: "text-white/50", onClick: onGoBack }, { children: [_jsx(FaArrowCircleLeft, {}), " Defibrillation"] })), _jsxs("div", __assign({ className: "flex flex-col gap-1" }, { children: [_jsxs("div", __assign({ className: "w-full h-8 flex justify-between items-center" }, { children: [_jsx("span", __assign({ className: "w-auto" }, { children: "Pads" })), padsApplied ? (_jsx(FaCheck, { className: "text-green-500" })) : (_jsx("button", __assign({ className: "w-auto text-center block bg-green-500 h-full px-2 rounded text-white", onClick: handlePadsApply }, { children: "Apply" })))] })), _jsxs("div", __assign({ className: "flex w-72 h-8 justify-between ".concat(padsApplied ? "" : "opacity-50 pointer-events-none") }, { children: [_jsxs("div", __assign({ className: "flex items-center gap-2" }, { children: [_jsx("label", __assign({ className: "text-white" }, { children: "Energy (J):" })), _jsx("input", { type: "number", value: joules, min: 0, max: 400, onChange: function (e) { setJoules(Number(e.target.value)); setCharged(false); }, className: "w-20 p-1 rounded-md" })] })), !charged ?
                                _jsx("button", __assign({ className: "w-auto text-center block bg-green-500 py-1 px-4 rounded text-white", onClick: handleCharge }, { children: charged ? "Charged" : "Charge" }))
                                :
                                    _jsx("button", __assign({ className: "w-auto text-center block bg-red-500 py-1 px-4 rounded text-white", onClick: handleShock }, { children: "Shock" }))] }))] }))] })));
};
export default DefibControls;
