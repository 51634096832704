export function Metadata(userUid, userName, email) {
    var timestamp = new Date();
    return {
        ownerId: userUid,
        ownerName: userName,
        email: email,
        createdAt: timestamp,
        updatedAt: timestamp
    };
}
