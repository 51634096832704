var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useEffect } from "react";
import MonitorTestIndex from "../../components/Monitor/MonitorTest";
import url_44yo_back from "./44yo-back.png";
import url_44yo_side from "./44yo-side.png";
var MegaScreenTest = function () {
    var _a = useState(new Date().toLocaleTimeString()), time = _a[0], setTime = _a[1];
    useEffect(function () {
        var interval = setInterval(function () {
            setTime(new Date().toLocaleTimeString());
        }, 1000);
        return function () { return clearInterval(interval); }; // Cleanup the interval on component unmount
    }, []);
    return (_jsxs("div", __assign({ className: "w-full h-full bg-black" }, { children: [_jsx("img", { src: url_44yo_back, alt: "44yo", className: "absolute left-[100px] bottom-[100px] h-3/4 object-contain" }), _jsx("img", { src: url_44yo_side, alt: "44yo", className: "absolute left-[500px] bottom-[100px] h-3/4 object-contain" }), _jsx("div", __assign({ className: "w-[1000px] absolute top-[100px] right-[100px] div-4 scale-[40%] bg-gray-900 origin-top-right rounded-xl p-2 shadow-2xl shadow-white/10" }, { children: _jsx(MonitorTestIndex, {}) })), _jsxs("div", __assign({ className: "bg-white/10 rounded-xl absolute bottom-[100px] right-[100px] div-4 text-xs w-72 p-4 flex flex-col gap-2 text-white shadow-2xl shadow-white/10" }, { children: [_jsxs("div", { children: [_jsx("b", { children: "Allergy" }), " - ekkert ofn\u00E6mi"] }), _jsxs("div", { children: [_jsx("b", { children: "Medications" }), " - tekur engin lyf. F\u00E9kk parasetam\u00F3l st\u00EDl s\u00ED\u00F0ast \u00ED g\u00E6rkv\u00F6ldi."] }), _jsxs("div", { children: [_jsx("b", { children: "Past medical history" }), " - hraust og full b\u00F3lusett."] }), _jsxs("div", { children: [_jsx("b", { children: "Last meal" }), " - Ekkert n\u00E6rst \u00ED dag, drakk vel af vatni \u00ED n\u00F3tt en minni matarlyst"] }), _jsxs("div", { children: [_jsx("b", { children: "Events" }), " - 4 daga s\u00F6gu um hita, kvef og h\u00F3sta. Hiti legi\u00F0 milli 38 - 39 gr\u00E1\u00F0ur. Blautur h\u00F3sti og nefrennsli. Uppk\u00F6st \u00ED g\u00E6r og \u00ED n\u00F3tt. Hefur kasta\u00F0 upp samtals um 4 - 5 sinnum. Ekki ni\u00F0urgangur en linar h\u00E6g\u00F0ir."] })] })), _jsx("div", __assign({ className: "bg-white/10 rounded-xl absolute top-[50px] left-1/2 text-center div-4 text-xs w-72 p-4 flex flex-col gap-2 text-white shadow-2xl shadow-white/10" }, { children: time }))] })));
};
export default MegaScreenTest;
