var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { FaUser } from "react-icons/fa6";
import VirtualPatientStateStorage from "../../components/Database/VirtualPatientStateStorage";
import { FaArrowRight } from "react-icons/fa";
var PatientPresetBox = function (_a) {
    var doc = _a.doc, onClick = _a.onClick;
    var virtualPatientState = doc.virtualPatientState;
    var _b = useState(false), isOpen = _b[0], setIsOpen = _b[1];
    return (_jsxs("div", { children: [_jsx(motion.header, __assign({ className: "bg-mirno-dark text-mirno-light cursor-pointer hover:brightness-125 rounded-md shadow-md z-10 relative", initial: false, onClick: function () { return setIsOpen(function (prev) { return !prev; }); } }, { children: _jsxs(motion.div, __assign({ className: 'flex gap-2 h-full w-full items-center h-16 overflow-hidden p-1' }, { children: [_jsx("div", __assign({ className: "h-full self-center pr-2 pl-2 text-mirno-accent-blue flex items-center" }, { children: _jsx(FaUser, { className: 'text-2xl' }) })), _jsxs("div", __assign({ className: 'w-full flex flex-col' }, { children: [_jsxs("div", __assign({ className: 'flex w-full items-center gap-1' }, { children: [_jsx("div", __assign({ className: 'font-bold' }, { children: virtualPatientState.name })), _jsx("div", __assign({ className: 'text-sm text-gray-400' }, { children: virtualPatientState.staticData.patientProfile.patientType }))] })), _jsx("div", __assign({ className: 'text-sm text-gray-400 overflow-hidden text-ellipsis italic', style: {
                                        display: '-webkit-box',
                                        WebkitLineClamp: 1,
                                        WebkitBoxOrient: 'vertical',
                                    } }, { children: virtualPatientState.description || 'No description' }))] })), _jsx("div", __assign({ className: "hover:brightness-125 cursor-pointer rounded-full bg-white/10 aspect-square mr-2 scale-150 z-50", onClick: function (e) {
                                e.stopPropagation();
                                e.preventDefault();
                                onClick();
                            } }, { children: _jsx(FaArrowRight, { className: "text-2xl text-mirno-green p-1" }) }))] })) })), _jsx(AnimatePresence, __assign({ initial: false }, { children: isOpen && (_jsx(motion.section, __assign({ initial: "collapsed", animate: "open", exit: "collapsed", variants: {
                        open: { opacity: 1, height: "auto" },
                        collapsed: { opacity: 0, height: 0 }
                    }, className: "" }, { children: _jsxs(motion.div, __assign({ layout: true, className: "overflow-hidden bg-mirno-dark p-2 ml-2 mr-2 rounded rounded-tl-none rounded-tr-none text-white" }, { children: [_jsx("div", __assign({ className: 'flex text-xs justify-between' }, { children: _jsx("div", { children: "Progression states: None" }) })), _jsx("div", __assign({ className: 'flex text-xs justify-between' }, { children: _jsxs("div", { children: ["Owner: ", doc.metadata.ownerName, doc.metadata.email ? " - " + doc.metadata.email : ""] }) }))] })) }), "content")) }))] }));
};
var PatientSelector = function (_a) {
    var className = _a.className, onSelection = _a.onSelection;
    var _b = useState([]), presets = _b[0], setPresets = _b[1];
    function loadPresets() {
        return __awaiter(this, void 0, void 0, function () {
            var presets;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, VirtualPatientStateStorage.listPresets()];
                    case 1:
                        presets = _a.sent();
                        setPresets(presets);
                        return [2 /*return*/];
                }
            });
        });
    }
    useEffect(function () {
        loadPresets();
    }, []);
    return (_jsx("div", __assign({ className: className }, { children: _jsx("div", __assign({ className: "h-full w-full overflow-y-auto overflow-x-hidden" }, { children: _jsx("div", __assign({ className: "flex flex-col gap-2" }, { children: presets.map(function (doc, key) { return (_jsx(PatientPresetBox, { doc: doc, onClick: function () { return onSelection(doc); } }, key)); }) })) })) })));
};
export default PatientSelector;
