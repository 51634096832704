var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useEffect, useState } from 'react';
import { confirmDiscardChanges, useAppContext } from '../../../AppContext';
import PresetSelectorModal from '../../../components/PresetSelector';
import VirtualPatientStateStorage from '../../../components/Database/VirtualPatientStateStorage';
import SaveButton from './SaveButton';
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react';
import { useVirtualPatientStore } from '../../../storage/VirtualPatientStore';
var SidebarMenuBar = function () {
    var _a = useAppContext(), isModified = _a.isModified, loadPreset = _a.loadPreset, resetToDefault = _a.resetToDefault;
    var virtualPatientState = useVirtualPatientStore().virtualPatientState;
    var _b = useState([]), setPresets = _b[1];
    // const navigate = useNavigate();
    var _c = useState(false), showPresetModal = _c[0], setShowPresetModal = _c[1];
    useEffect(function () {
        VirtualPatientStateStorage.listPresets().then(function (presets) {
            setPresets(presets);
        });
    }, []);
    function onPresetSelected(preset) {
        if (isModified() && !confirmDiscardChanges())
            return;
        loadPreset(preset.virtualPatientState);
        setShowPresetModal(false);
    }
    function onResetToDefaultClicked() {
        if (isModified() && !confirmDiscardChanges())
            return;
        resetToDefault();
    }
    // function goToMaia() {
    //     navigate('/maia');
    // }
    return (_jsxs(_Fragment, { children: [_jsxs("div", __assign({ className: 'flex flex-col w-full bg-mirno-dark' }, { children: [_jsxs("div", __assign({ className: 'flex gap-2 p-1' }, { children: [_jsxs(Menu, { children: [_jsx(MenuButton, __assign({ className: 'mirnoMenuButton' }, { children: "File" })), _jsxs(MenuItems, __assign({ anchor: "bottom", className: 'p-1 flex flex-col bg-mirno-dark gap-1 rounded-b-xl pl-2 mt-1' }, { children: [_jsx(MenuItem, { children: _jsx("div", __assign({ className: 'mirnoMenuButton', onClick: onResetToDefaultClicked }, { children: "New" })) }), _jsx(MenuItem, { children: _jsx("div", __assign({ className: 'mirnoMenuButton', onClick: function () { return setShowPresetModal(true); } }, { children: "Load" })) })] }))] }), isModified() && (_jsx("div", __assign({ className: 'ml-auto' }, { children: _jsx(SaveButton, {}) })))] })), _jsxs("div", __assign({ className: "flex items-center justify-between p-2 bg-gray-100 gap-2" }, { children: [virtualPatientState.name !== undefined && (_jsxs("div", __assign({ className: 'font-bold flex-auto' }, { children: [virtualPatientState.name, isModified() && (_jsx("span", __assign({ className: 'pl-1 font-light text-red-700' }, { children: "(unsaved)" })))] }))), virtualPatientState.name === undefined && (_jsx("div", __assign({ className: 'font-thin flex-auto' }, { children: "Untitled" })))] }))] })), _jsx(PresetSelectorModal, { isOpen: showPresetModal, onClose: function () { return setShowPresetModal(false); }, onSelectedPreset: onPresetSelected })] }));
};
export default SidebarMenuBar;
