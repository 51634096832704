import { jsx as _jsx } from "react/jsx-runtime";
import { Route } from "react-router-dom";
import VPCastIndex from "./VPCastIndex";
import VPCastControllerBasic from "./VPCastController_Basic";
import VPCastControllerPartyMode from "./PartyMode/VPCastController_PartyMode";
import VPCastMonitor from "./VPCastMonitor";
export var PARTY_MODE_CONTROLLER_ROUTE = "/vpcast/pmc";
export var VPCastRoutes = [
    _jsx(Route, { path: "/vpcast", element: _jsx(VPCastIndex, {}) }),
    _jsx(Route, { path: "/vpcast/controller", element: _jsx(VPCastControllerBasic, {}) }),
    _jsx(Route, { path: PARTY_MODE_CONTROLLER_ROUTE, element: _jsx(VPCastControllerPartyMode, {}) }),
    _jsx(Route, { path: "/vpcast/monitor", element: _jsx(VPCastMonitor, {}) })
];
