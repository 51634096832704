var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import MirnoIcon from "../../assets/mirno_icon.png";
import { IoClose } from "react-icons/io5";
import { motion } from "framer-motion";
var CinemedicModal = function (_a) {
    var children = _a.children, title = _a.title, subtitle = _a.subtitle, onClose = _a.onClose, _b = _a.canClose, canClose = _b === void 0 ? true : _b;
    return (_jsx(motion.div, __assign({ className: "absolute w-full h-full flex justify-center items-center bg-slate-800 bg-opacity-90 z-[99999999] pointer-events-auto", initial: { opacity: 0 }, animate: { opacity: 1 }, exit: { opacity: 0 }, transition: {
            duration: 0.3,
            delay: 0,
            exit: { duration: 0.3, delay: 0.3 }, // Delay on close
        } }, { children: _jsxs(motion.div, __assign({ className: "bg-slate-700 rounded-lg p-2 h-1/2 w-1/2 max-w-[40rem] flex flex-col text-white shadow-lg overflow-hidden", initial: { opacity: 0, scale: 0 }, animate: { opacity: 1, scale: 1 }, exit: { opacity: 0, scale: 0 }, transition: { duration: 0.3 } }, { children: [_jsxs("div", __assign({ className: "relative flex w-full h-12 items-center mb-2 p-2" }, { children: [_jsx("img", { src: MirnoIcon, alt: "Mirno Icon", className: "h-8 aspect-square absolute" }), _jsxs("div", __assign({ className: "w-full flex flex-col items-center" }, { children: [_jsx("h1", __assign({ className: "text-2xl text-center" }, { children: title })), subtitle && _jsx("span", __assign({ className: "text-sm text-center text-slate-400" }, { children: subtitle }))] })), canClose &&
                            _jsx("div", __assign({ className: "absolute right-2 h-full text-red-200 flex items-center" }, { children: _jsx(IoClose, { className: "text-2xl cursor-pointer", onClick: onClose }) }))] })), _jsx("div", __assign({ className: "w-full h-full overflow-hidden" }, { children: children }))] })) })));
};
export default CinemedicModal;
