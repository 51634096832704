var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
// ArcwareView.tsx
import React, { useEffect, useRef } from "react";
// import ArcwareSingleton from "./ArcwareSingleton";
import { useAppContext } from "../../AppContext";
import { motion } from "framer-motion";
import MirnoIcon from "../../assets/mirno_icon.png";
import { WebRTCClient } from '@arcware/webrtc-plugin';
var ArcwareView = function () {
    var _a = useAppContext(), setArcwareWebRTC = _a.setArcwareWebRTC, subscribeToArcwareWebRTCReady = _a.subscribeToArcwareWebRTCReady;
    // const videoContainerRef = useRef<HTMLDivElement>(null);
    var autoConnect = React.useState(process.env.NODE_ENV !== 'development' && false)[0];
    // const arcwareInstance = ArcwareSingleton.getInstance();
    var _b = React.useState(false), isLoading = _b[0], setIsLoading = _b[1];
    var _c = React.useState(false), isLoaded = _c[0], setIsLoaded = _c[1];
    var sizeContainerRef = useRef(null);
    var videoContainerRef = useRef(null);
    var videoRef = useRef(null);
    var audioRef = useRef(null);
    // const [setWebRTCclient] = React.useState<WebRTCClient | null>(null);
    useEffect(function () {
        var originalAddEventListener = document.addEventListener.bind(document);
        document.addEventListener = function (type, listener, options) {
            var naughtyListenerSignature = "!(\"Space\" === e.code && t instanceof HTMLElement) || t instanceof HTMLInputElement || t instanceof HTMLTextAreaElement || t.blur();";
            if (type === 'keydown' && listener.toString().indexOf(naughtyListenerSignature) !== -1) {
                console.log("Prevented naughty listener");
                return;
            }
            originalAddEventListener(type, listener, options);
        };
        if (autoConnect) {
            connectWebRTC();
        }
    });
    var webRtcConnectedCallback = function () {
        // console.log("onConnected - arcwareWebRTCReady:", arcwareWebRTCReady);
        // if (!arcwareWebRTCReady) {
        //     return;
        // }
        setIsLoading(false);
        setIsLoaded(true);
    };
    var connectWebRTC = React.useCallback(function () {
        setIsLoading(true);
        subscribeToArcwareWebRTCReady(webRtcConnectedCallback);
        var newWebRTC = new WebRTCClient({
            address: 'wss://signalling-client.ragnarok.arcware.cloud/',
            shareId: 'share-e3ce2bdd-730b-4ecd-a8cc-f63b7da2d8fc',
            settings: {},
            playOverlay: false,
            loader: function (val) { },
            applicationResponse: function (response) {
                console.log('Application response:', response);
            },
            packageId: "a277a979-c54f-452e-977e-c3610432e696",
            sizeContainer: sizeContainerRef.current,
            container: videoContainerRef.current,
            videoRef: videoRef.current,
            audioRef: audioRef.current,
        });
        console.log("New WebRTC:", newWebRTC);
        setArcwareWebRTC(newWebRTC);
    }, [setArcwareWebRTC, sizeContainerRef, videoContainerRef, videoRef, audioRef, subscribeToArcwareWebRTCReady]);
    var connectNow = React.useCallback(function () {
        connectWebRTC();
    }, [connectWebRTC]);
    // useEffect(() => {
    //     // arcwareInstance.initializeArcware(autoConnect);
    //     setIsLoading(autoConnect);
    // }, [arcwareInstance, autoConnect]);
    // const connectNow = React.useCallback(() => {
    //     arcwareInstance.connect();
    //     setIsLoading(true);
    //     webRTCClient!.play();
    // }, [arcwareInstance]);
    // useEffect(() => {
    //     const arcwareApplication = arcwareInstance.getArcwareApplication();
    //     setArcwareApp(arcwareApplication);
    //     if (arcwareApplication) {
    //         // Force high quality encoding
    //         arcwareApplication.emitUIInteraction({ Console: "PixelStreaming.Encoder.MaxQP 0" });
    //     }
    // }, [arcwareInstance, setArcwareApp]);
    // useEffect(() => {
    //     const pixelStreaming = arcwareInstance.getPixelStreaming();
    //     if (pixelStreaming) {
    //         pixelStreaming.videoInitializedHandler.add(() => {
    //             emitSimulationStateToArcware();
    //             pixelStreaming.onStreamingStateChange((isStreaming) => {
    //                 if (isStreaming) {
    //                     emitSimulationStateToArcware();
    //                     setIsLoading(false);
    //                     setIsLoaded(true);
    //                 }
    //             });
    //         });
    //     }
    // }, [arcwareInstance, emitSimulationStateToArcware]);
    // useEffect(() => {
    //     const arcwareApplication = arcwareInstance.getArcwareApplication();
    //     if (arcwareApplication && videoContainerRef?.current) {
    //         if (videoContainerRef.current.firstChild === null) {
    //             videoContainerRef.current.appendChild(arcwareApplication.rootElement);
    //         }
    //     }
    // }, [arcwareInstance]);
    return (_jsxs("div", __assign({ className: "w-full h-full relative" }, { children: [_jsx("div", __assign({ ref: sizeContainerRef, className: "w-full h-full" }, { children: _jsxs("div", __assign({ ref: videoContainerRef }, { children: [_jsx("video", { ref: videoRef }), _jsx("audio", { ref: audioRef })] })) })), _jsx(motion.div, __assign({ initial: { opacity: 1 }, animate: { opacity: isLoaded ? 0 : 1 }, exit: { opacity: 0 }, transition: { duration: 0.5, delay: 1 }, className: "w-full h-full z-[100] absolute top-0 left-0 bg-mirno-dark pointer-events-none" }, { children: _jsx("div", __assign({ className: "w-full h-full flex flex-col items-center justify-center" }, { children: isLoading || isLoaded ?
                        _jsxs(_Fragment, { children: [_jsx(motion.div, __assign({ animate: {
                                        scale: [1, 1.1, 1, 1, 1.1, 1],
                                    }, transition: {
                                        repeat: Infinity,
                                        duration: 1,
                                        times: [0, 0.1, 0.25, 0.3, 0.4, 0.55],
                                        ease: "easeInOut",
                                    } }, { children: _jsx("img", { src: MirnoIcon, alt: "Mirno Icon", className: "h-8 aspect-square" }) })), _jsx("p", __assign({ className: "text-white mt-4" }, { children: "Preparing Virtual Patient..." }))] })
                        :
                            _jsx("p", __assign({ className: "text-white mt-4 pointer-events-auto cursor-pointer", onClick: connectNow }, { children: "Click to connect to Virtual Patient" })) })) }))] })));
};
export default ArcwareView;
