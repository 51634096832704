var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { Route } from "react-router-dom";
import { AppProvider, useAppContext } from "../../AppContext";
import { SessionProvider, useSession } from "../Sessions/SessionContext";
import CinemedicView from "./CinemedicView";
import CinemedicToolbarTestIndex from "./Toolbar/CinemedicToolbarTest";
import { useEffect } from "react";
import React from "react";
import { QRCodeSVG } from "qrcode.react";
import { useVirtualPatientStore } from "../../storage/VirtualPatientStore";
import { UE5MessageType } from "../../components/ue5";
// import VirtualPatientStateStorage from "../../components/Database/VirtualPatientStateStorage";
import { PARTY_MODE_CONTROLLER_ROUTE } from "../VPCast/VPCastRoutes";
import MegaScreenTest from "./MegaScreenTest";
import CinemedicProIndex from "./CinemedicPro";
import VirtualPatientStateStorage from "../../components/Database/VirtualPatientStateStorage";
var CinemedicIndex = function () {
    return (_jsx(AppProvider, { children: _jsx(SessionProvider, { children: _jsx(CinemedicView, { pixelStreaming: true }) }) }));
};
export var CinemedicUnreal = function () {
    return (_jsx(AppProvider, { children: _jsx(SessionProvider, { children: _jsx(CinemedicView, { pixelStreaming: false }) }) }));
};
var loadedInitialPatientState = false;
export var CinemedicInEngineSessionView = function () {
    var _a = useSession(), fullSessionData = _a.fullSessionData, sessionId = _a.sessionId, createSession = _a.createSession, onMessageReceived = _a.subscribeToMessages, writeDataToSession = _a.writeDataToSession;
    var _b = React.useState(''), sessionControllerURL = _b[0], setSessionControllerURL = _b[1];
    var _c = useVirtualPatientStore(), setVirtualPatientState = _c.setVirtualPatientState, virtualPatientState = _c.virtualPatientState;
    var broadcastMessage = useAppContext().broadcastMessage;
    useEffect(function () {
        function setup() {
            return __awaiter(this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            if (sessionId) {
                                return [2 /*return*/];
                            }
                            return [4 /*yield*/, createSession()];
                        case 1:
                            _a.sent();
                            return [2 /*return*/];
                    }
                });
            });
        }
        setup();
    });
    useEffect(function () {
        if (loadedInitialPatientState || !sessionId) {
            return;
        }
        else {
            loadedInitialPatientState = true;
        }
        var presetId = "UqgY1SjPMmVVSseG2vsX";
        VirtualPatientStateStorage.getPreset(presetId).then(function (preset) {
            if (preset) {
                console.log("Preset loaded: ", preset);
                writeDataToSession({
                    virtualPatientState: preset.virtualPatientState
                });
            }
            else {
                console.error("Preset not found:", presetId);
            }
        });
    }, [sessionId, writeDataToSession]);
    var virtualPatientStateRef = React.useRef(virtualPatientState);
    useEffect(function () {
        virtualPatientStateRef.current = virtualPatientState;
    }, [virtualPatientState]);
    useEffect(function () {
        if (sessionId) {
            console.log("writing virtual patient state to session", sessionId);
            writeDataToSession({
                virtualPatientState: virtualPatientStateRef.current
            });
            setSessionControllerURL("".concat(window.location.origin).concat(PARTY_MODE_CONTROLLER_ROUTE, "?sessionId=").concat(sessionId));
        }
    }, [sessionId, writeDataToSession, setSessionControllerURL]);
    useEffect(function () {
        if (!fullSessionData || !fullSessionData.data) {
            return;
        }
        if (fullSessionData.data.virtualPatientState) {
            console.log("Received virtual patient state", fullSessionData.data.virtualPatientState);
            setVirtualPatientState(fullSessionData.data.virtualPatientState);
        }
    }, [fullSessionData, setVirtualPatientState]);
    useEffect(function () {
        var handleMessage = function (message) {
            if (message.type === UE5MessageType.setCamera) {
                var cameraId = message.cameraId;
                var cameraName = ["top", "side", "face"][cameraId];
                console.log("Setting camera to ".concat(cameraName));
                broadcastMessage(UE5MessageType.setCamera, { name: cameraName });
            }
        };
        // Register the callback and get the unregister function
        var unregister = onMessageReceived(handleMessage);
        // Automatically unregister the callback when the component unmounts
        return function () {
            unregister();
        };
    }, [onMessageReceived, broadcastMessage]);
    if (!sessionId) {
        return _jsx(_Fragment, {});
    }
    return (_jsx("a", __assign({ href: sessionControllerURL, target: "_blank", rel: "noreferrer", className: "w-full h-full" }, { children: _jsx(QRCodeSVG, { value: sessionControllerURL, includeMargin: true, bgColor: '#ffffff', fgColor: '#000000', className: "w-full h-full" }) })));
};
export var CinemedicInEngineSession = function () {
    return (_jsx(AppProvider, { children: _jsx(SessionProvider, { children: _jsx(CinemedicInEngineSessionView, {}) }) }));
};
export var CinemedicRoutes = [
    _jsx(Route, { path: "/cinemedic/desktop/:patientId?", element: _jsx(CinemedicIndex, {}) }),
    _jsx(Route, { path: "/cinemedic/toolbar", element: _jsx(CinemedicToolbarTestIndex, {}) }),
    _jsx(Route, { path: "/cinemedic/in-engine-session", element: _jsx(CinemedicInEngineSession, {}) }),
    _jsx(Route, { path: "/cinemedic/megascreen", element: _jsx(MegaScreenTest, {}) }),
    _jsx(Route, { path: "/cinemedic/pro", element: _jsx(CinemedicProIndex, {}) }),
];
export default CinemedicIndex;
