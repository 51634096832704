import { DefaultStaticData } from "./PatientState";
import { LungSound } from "./components/Breathing";
import { HeartSound } from "./components/Circulation";
import { ActivityState, Posture } from "./components/Disability";
export var DefaultMedicalState = {
    vitals: {
        heartRate: 60,
        respiratoryRate: 35,
        spo2: 100,
        nibp: { systolic: 120, diastolic: 60 },
        etco2: 35
    },
    breathingSounds: {
        normal: 0.05,
        grunting: 0,
        gurgling: 0,
        stridor: 0,
        wheezing: 0,
        snoring: 0,
        leftLungSound: LungSound.Normal,
        rightLungSound: LungSound.Normal
    },
    workOfBreathing: {
        headBobbing: 0,
        nasalFlaring: 0,
        retractions: 0,
        sterno: 0
    },
    circulation: {
        capillaryRefillSeconds: 3,
        heartSound: HeartSound.Normal
    },
    skin: {
        faceRedness: 0,
        sweaty: 0,
        cyanosisBody: 0,
        cyanosisFace: 0,
        liverEnlargement: 0,
        mottled: 0,
        paleness: 0
    },
    disability: {
        activity: ActivityState.Calm,
        avpu: "A",
        posture: Posture.Normal,
        leftPupilSize: 6,
        leftPupilSluggishness: 0,
        rightPupilSize: 6,
        rightPupilSluggishness: 0
    },
    exposure: {
        burns: 0,
        petechiae: 0,
        purpura: 0,
        rashes: 0,
        swelling: 0,
        urticaria: 0,
        wounds: 0
    },
};
export var DefaultDeviceState = {
    ecg: false,
    ivCatheterLeftArm: false,
    ivCatheterRightArm: false,
    ivCatheterLeftHand: false,
    ivCatheterRightHand: false,
    nasalCannula: false,
    nibpLeftArm: false,
    nibpRightArm: false,
    pulseOximeterLeftFoot: false,
    pulseOximeterRightFoot: false,
};
export var DefaultExaminationState = {
    clothed: true,
    eyesOpen: false,
    mouthOpen: false
};
export var DefaultVirtualPatientState = {
    deviceStatus: DefaultDeviceState,
    examinationState: DefaultExaminationState,
    medicalState: DefaultMedicalState,
    name: undefined,
    description: undefined,
    staticData: DefaultStaticData,
    progressionData: null
};
