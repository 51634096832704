var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { motion } from "framer-motion";
import { useState } from "react";
import { FaArrowCircleLeft } from "react-icons/fa";
import { useVirtualPatientStore } from "../../../../storage/VirtualPatientStore";
var IVAccessControls = function (_a) {
    var onGoBack = _a.onGoBack, onIVApplied = _a.onIVApplied, onIVRemoved = _a.onIVRemoved;
    var _b = useState("Left Arm"), location = _b[0], setLocation = _b[1];
    var _c = useState("18G"), gauge = _c[0], setGauge = _c[1];
    var _d = useState("Angiocath"), catheterType = _d[0], setCatheterType = _d[1];
    var virtualPatientState = useVirtualPatientStore().virtualPatientState;
    var handleApplyIV = function () {
        if (onIVApplied)
            onIVApplied(location, gauge, catheterType);
    };
    var handleRemoveIV = function () {
        console.log("IV removed");
        // Reset selections if needed
        setLocation("Left Arm");
        setGauge("18G");
        setCatheterType("Angiocath");
        if (onIVRemoved)
            onIVRemoved();
    };
    return (_jsxs(motion.div, __assign({ className: "h-auto w-full flex flex-col gap-2 content-center bg-mirno-dark inline", initial: { opacity: 0 }, animate: { opacity: 1 }, exit: { opacity: 0 }, transition: { duration: 0.3 } }, { children: [_jsxs("button", __assign({ className: "text-white/50 flex items-center gap-1", onClick: onGoBack }, { children: [_jsx(FaArrowCircleLeft, {}), " IV Access"] })), _jsxs("div", __assign({ className: "flex flex-col gap-4" }, { children: [_jsxs("div", __assign({ className: "flex justify-between items-center" }, { children: [_jsx("label", __assign({ className: "text-white" }, { children: "Location:" })), _jsx("select", __assign({ value: location, onChange: function (e) { return setLocation(e.target.value); }, className: "p-1 rounded-md", disabled: virtualPatientState.deviceStatus.ivCatheterLeftArm || true }, { children: _jsx("option", __assign({ value: "Left Arm" }, { children: "Left Arm" })) }))] })), _jsx("div", __assign({ className: "flex justify-end items-center" }, { children: virtualPatientState.deviceStatus.ivCatheterLeftArm ? (_jsx("button", __assign({ className: "bg-red-500 py-1 px-4 rounded text-white", onClick: handleRemoveIV }, { children: "Remove" }))) : (_jsx("button", __assign({ className: "bg-green-500 py-1 px-4 rounded text-white", onClick: handleApplyIV }, { children: "Apply" }))) }))] }))] })));
};
export default IVAccessControls;
