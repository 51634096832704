var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState, useRef } from "react";
import { motion, useMotionValue, animate } from "framer-motion";
import { Menu, MenuButton, MenuHeading, MenuItem, MenuItems, MenuSection } from "@headlessui/react";
var BasicToolbarMenuButton = function (_a) {
    var title = _a.title;
    var menuButtonClassName = "h-auto rounded p-1 border-[1.5px] border-white text-white hover:brightness-125 text-left text-white text-xs cursor-pointer flex items-center gap-2";
    return (_jsx(MenuButton, __assign({ className: menuButtonClassName }, { children: title })));
};
var DevelopmentMenu = function (_a) {
    var title = _a.title, content = _a.content;
    return (_jsxs(Menu, { children: [_jsx(BasicToolbarMenuButton, { title: title }), _jsx(MenuItems, __assign({ transition: true, anchor: "bottom start", className: "rounded border-[1.5px] border-white text-white bg-mirno-dark z-[999999999] transition duration-200 ease-out data-[closed]:scale-50 data-[closed]:opacity-0 origin-bottom-left" }, { children: content.map(function (sectionData, i) { return (_jsxs(MenuSection, __assign({ className: "text-xs p-1" }, { children: [_jsx(MenuHeading, __assign({ className: "opacity-50" }, { children: sectionData.section })), sectionData.items.map(function (item, j) { return (_jsx(MenuItem, { children: _jsx("button", __assign({ className: "group flex w-full items-center gap-2 rounded-lg py-1.5 px-3 data-[focus]:bg-white/10" }, { children: item })) }, j)); })] }), i)); }) }))] }));
};
export var ToolbarContent = function () {
    return (_jsxs("div", __assign({ className: "h-full w-full grid grid-cols-4 gap-1 content-center" }, { children: [_jsx(DevelopmentMenu, { title: "A", content: [
                    {
                        section: "Assessments",
                        items: ["Breathing sounds", "Obstructions", "Airway Patency"]
                    },
                    {
                        section: "Interventions",
                        items: ["Oxygen - nasal cannula", "Bag mask ventilation", "Endotracheal intubation", "Suctioning"]
                    }
                ] }), _jsx(DevelopmentMenu, { title: "B", content: [
                    {
                        section: "Assessments",
                        items: ["Lung sounds", "Chest rise", "Pulse Oximetry"]
                    },
                    {
                        section: "Interventions",
                        items: ["Chest tube insertion", "Needle decompression", "Non-invasive ventilation"]
                    }
                ] }), _jsx(DevelopmentMenu, { title: "C", content: [
                    {
                        section: "Assessments",
                        items: ["Heart sounds", "Blood Pressure", "Pulse Strength"]
                    },
                    {
                        section: "Interventions",
                        items: ["IV Fluids", "Blood transfusion", "CPR", "Defibrillation"]
                    }
                ] }), _jsx(DevelopmentMenu, { title: "D", content: [
                    {
                        section: "Assessments",
                        items: ["AVPU", "GCS", "Pupil response"]
                    },
                    {
                        section: "Interventions",
                        items: ["Sedatives", "Pain Management"]
                    }
                ] }), _jsx(DevelopmentMenu, { title: "E", content: [
                    {
                        section: "Assessments",
                        items: ["Blood glucose", "Temperature", "Skin assessment"]
                    },
                    {
                        section: "Interventions",
                        items: ["Warming blankets", "Bleeding control"]
                    }
                ] }), _jsx(DevelopmentMenu, { title: "Reports", content: [
                    {
                        section: "Reports",
                        items: ["Blood gas", "AMPLE", "ECG", "Chest X-ray", "Lab Results"]
                    }
                ] }), _jsx(DevelopmentMenu, { title: "Medications", content: [
                // {
                //     section: "Medications",
                //     items: ["Blood gas", "AMPLE", "ECG", "Chest X-ray", "Lab Results"]
                // }
                ] })] })));
};
var DraggableToolbar = function (_a) {
    var _b = _a.x, initialX = _b === void 0 ? 0 : _b, _c = _a.y, initialY = _c === void 0 ? 0 : _c, initialRotation = _a.initialRotation, onClose = _a.onClose, onToggleMonitor = _a.onToggleMonitor, onToggleBloodGasReport = _a.onToggleBloodGasReport;
    var _d = useState(false), isDragging = _d[0], setIsDragging = _d[1];
    var toolbarRef = useRef(null);
    var dragConstraintsRef = useRef(null);
    var canDrag = useState(true)[0];
    var x = useMotionValue(initialX);
    var y = useMotionValue(initialY);
    var rotation = useMotionValue(initialRotation);
    var margin = 16;
    var snapToEdge = React.useCallback(function (dragX, dragY) {
        var _a;
        var windowWidth = window.innerWidth;
        var windowHeight = window.innerHeight;
        var toolbarWidth = ((_a = toolbarRef.current) === null || _a === void 0 ? void 0 : _a.offsetWidth) || 0;
        var distances = {
            left: dragX,
            right: windowWidth - dragX,
            top: dragY,
            bottom: windowHeight - dragY,
        };
        var minDistanceEdge = Object.keys(distances).reduce(function (a, b) { return distances[a] < distances[b] ? a : b; });
        function getClampedY() {
            var widthPlusMargin = toolbarWidth / 2 + margin;
            return Math.max(widthPlusMargin, Math.min(windowHeight - widthPlusMargin, y.get()));
        }
        function getClampedX() {
            var widthPlusMargin = toolbarWidth / 2 + margin;
            return Math.max(widthPlusMargin, Math.min(windowWidth - widthPlusMargin, x.get()));
        }
        switch (minDistanceEdge) {
            case 'left':
                return { x: margin, y: getClampedY(), rotation: 90 };
            case 'right':
                return { x: windowWidth - margin, y: getClampedY(), rotation: -90 };
            case 'top':
                return { x: getClampedX(), y: margin, rotation: 180 };
            case 'bottom':
                return { x: getClampedX(), y: windowHeight - margin, rotation: 0 };
            default:
                return { x: x.get(), y: y.get(), rotation: 0 };
        }
    }, [x, y]);
    var handleDragEnd = function (event, info) {
        setIsDragging(false);
        var _a = info.point, dragX = _a.x, dragY = _a.y;
        var _b = snapToEdge(dragX, dragY), newX = _b.x, newY = _b.y, newRotation = _b.rotation;
        var desiredRotation = newRotation;
        var rotationDelta = newRotation - rotation.get();
        if (Math.abs(rotationDelta) > 180) {
            desiredRotation += rotationDelta > 0 ? -360 : 360;
        }
        animate(x, newX, { type: "spring", stiffness: 500, damping: 30 });
        animate(y, newY, { type: "spring", stiffness: 500, damping: 30 });
        animate(rotation, desiredRotation, { type: "spring", stiffness: 500, damping: 30 });
    };
    var handleDragStart = function () {
        setIsDragging(true);
    };
    React.useEffect(function () {
        var container = dragConstraintsRef.current;
        if (container) {
            var resizeObserver_1 = new ResizeObserver(function () {
                var currentX = x.get();
                var currentY = y.get();
                var _a = snapToEdge(currentX, currentY), newX = _a.x, newY = _a.y, newRotation = _a.rotation;
                animate(x, newX, { type: "spring", stiffness: 500, damping: 30 });
                animate(y, newY, { type: "spring", stiffness: 500, damping: 30 });
                animate(rotation, newRotation, { type: "spring", stiffness: 500, damping: 30 });
            });
            resizeObserver_1.observe(container);
            return function () {
                resizeObserver_1.disconnect();
            };
        }
    }, [x, y, rotation, snapToEdge]);
    var renderScale = 1;
    return (_jsx("div", __assign({ ref: dragConstraintsRef, className: "absolute w-full h-full pointer-events-none" }, { children: _jsx(motion.div, __assign({ ref: toolbarRef, drag: canDrag, dragMomentum: false, onDragEnd: handleDragEnd, onDragStart: handleDragStart, className: "fixed text-white rounded cursor-move pointer-events-auto ".concat(isDragging ? "z-100" : "z-90"), style: {
                x: x,
                y: y,
                rotate: rotation,
                transformOrigin: "0% 100%",
                translateY: "-100%",
            }, initial: { opacity: 0, scale: 0.2 }, animate: { opacity: 1, scale: renderScale }, exit: { opacity: 0, scale: 0.2 }, transition: { duration: 0.3, type: "spring", stiffness: 500, damping: 30 } }, { children: _jsx(motion.div, __assign({ className: "flex gap-1 text-slate-200 h-auto min-w-60 p-1 rounded bg-mirno-dark", layout: "size", style: { transformOrigin: "0% 50%" } }, { children: _jsx("div", { className: "w-full flex-grow" }) })) })) })));
};
export default DraggableToolbar;
