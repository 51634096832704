var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useRef, useState } from "react";
import DraggableToolbar from "./DraggableToolbar";
import { AnimatePresence } from "framer-motion";
import { useLongPress } from "use-long-press";
import { useAppContext } from "../../../AppContext";
import { UE5MessageType } from "../../../components/ue5";
var ToolbarController = function (props) {
    var containerRef = useRef(null);
    var broadcastMessage = useAppContext().broadcastMessage;
    var _a = useState([]), toolbars = _a[0], setToolbars = _a[1];
    var _b = useState(0), idCounter = _b[0], setIdCounter = _b[1];
    var handleLongPress = function (e, rotation) {
        e.preventDefault(); // Prevent default context menu on long press
        var rect = containerRef.current.getBoundingClientRect();
        var x = 'touches' in e ? e.touches[0].clientX - rect.left : e.clientX - rect.left;
        var y = 'touches' in e ? e.touches[0].clientY - rect.top : e.clientY - rect.top;
        var id = "toolbar-".concat(idCounter);
        setIdCounter(idCounter + 1);
        var newToolbar = (_jsx(DraggableToolbar, { x: x, y: y, initialRotation: rotation, onClose: function () { return handleClose(id); }, onToggleMonitor: props.onToggleMonitor, onToggleBloodGasReport: props.onToggleBloodGasReport }, id));
        setToolbars(__spreadArray(__spreadArray([], toolbars, true), [{ id: id, element: newToolbar }], false));
        broadcastMessage(UE5MessageType.setCamera.valueOf(), { name: "Top" });
    };
    useEffect(function () {
        if (toolbars.length === 0) {
            var rect = containerRef.current.getBoundingClientRect();
            var halfX = rect.width / 2;
            var halfY = rect.height / 2;
            var newToolbars = [
                {
                    id: "toolbar-0",
                    element: _jsx(DraggableToolbar, { x: 0, y: halfY, initialRotation: 0, onClose: function () { return handleClose("toolbar-0"); }, onToggleMonitor: props.onToggleMonitor, onToggleBloodGasReport: props.onToggleBloodGasReport }, "toolbar-0")
                },
                {
                    id: "toolbar-1",
                    element: _jsx(DraggableToolbar, { x: rect.width, y: halfY, initialRotation: 0, onClose: function () { return handleClose("toolbar-1"); }, onToggleMonitor: props.onToggleMonitor, onToggleBloodGasReport: props.onToggleBloodGasReport }, "toolbar-1")
                },
                {
                    id: "toolbar-2",
                    element: _jsx(DraggableToolbar, { x: halfX, y: 0, initialRotation: 90, onClose: function () { return handleClose("toolbar-2"); }, onToggleMonitor: props.onToggleMonitor, onToggleBloodGasReport: props.onToggleBloodGasReport }, "toolbar-2")
                },
                {
                    id: "toolbar-3",
                    element: _jsx(DraggableToolbar, { x: halfX, y: rect.height, initialRotation: -90, onClose: function () { return handleClose("toolbar-3"); }, onToggleMonitor: props.onToggleMonitor, onToggleBloodGasReport: props.onToggleBloodGasReport }, "toolbar-3")
                }
            ];
            setToolbars(newToolbars);
            setIdCounter(4);
        }
    }, [toolbars, setToolbars, props, setIdCounter]);
    var handleClose = function (id) {
        setToolbars(function (prevToolbars) {
            return prevToolbars.filter(function (toolbar) { return toolbar.id !== id; });
        });
    };
    var bindTopBorder = useLongPress(function (e) { return handleLongPress(e, 180); }, {
        threshold: 500,
        captureEvent: true,
    });
    var bindBottomBorder = useLongPress(function (e) { return handleLongPress(e, 0); }, {
        threshold: 500,
        captureEvent: true,
    });
    var bindLeftBorder = useLongPress(function (e) { return handleLongPress(e, 90); }, {
        threshold: 500,
        captureEvent: true,
    });
    var bindRightBorder = useLongPress(function (e) { return handleLongPress(e, -90); }, {
        threshold: 500,
        captureEvent: true,
    });
    var borderSize = "6rem";
    var borderClasses = "absolute pointer-events-auto cursor-pointer transparent mix-blend-normal";
    return (_jsxs("div", __assign({ className: "absolute w-full h-full pointer-events-none z-[9999999] bg-transparent", ref: containerRef }, { children: [_jsx("div", __assign({ className: "".concat(borderClasses, " top-0"), style: { height: borderSize, left: borderSize, right: borderSize } }, bindTopBorder())), _jsx("div", __assign({ className: "".concat(borderClasses, " bottom-0"), style: { height: borderSize, left: borderSize, right: borderSize } }, bindBottomBorder())), _jsx("div", __assign({ className: "".concat(borderClasses, " top-0 left-0 h-full"), style: { width: borderSize } }, bindLeftBorder())), _jsx("div", __assign({ className: "".concat(borderClasses, " top-0 right-0 h-full"), style: { width: borderSize } }, bindRightBorder())), _jsx(AnimatePresence, { children: toolbars.map(function (toolbar) { return toolbar.element; }) })] })));
};
export default ToolbarController;
