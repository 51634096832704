var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { motion } from "framer-motion";
import { useState } from "react";
import { FaArrowCircleLeft } from "react-icons/fa";
var BloodTestControls = function (_a) {
    var onGoBack = _a.onGoBack;
    var _b = useState("Blood Gas Analysis"), selectedTest = _b[0], setSelectedTest = _b[1];
    var _c = useState(false), testApplied = _c[0], setTestApplied = _c[1];
    var _d = useState(""), result = _d[0], setResult = _d[1];
    var handleTestApply = function () {
        setTestApplied(true);
        setResult(generateTestResult(selectedTest));
        console.log("Test applied: ".concat(selectedTest));
    };
    var handleTestRemove = function () {
        setTestApplied(false);
        setResult("");
        console.log("Test removed");
    };
    return (_jsxs(motion.div, __assign({ className: "h-auto w-full flex flex-col gap-2 content-center bg-mirno-dark inline", initial: { opacity: 0 }, animate: { opacity: 1 }, exit: { opacity: 0 }, transition: { duration: 0.3 } }, { children: [_jsxs("button", __assign({ className: "text-white/50 flex items-center gap-1", onClick: onGoBack }, { children: [_jsx(FaArrowCircleLeft, {}), " Blood Tests"] })), _jsxs("div", __assign({ className: "flex flex-col gap-4" }, { children: [_jsxs("div", __assign({ className: "flex justify-between items-center" }, { children: [_jsx("label", __assign({ className: "text-white" }, { children: "Test:" })), _jsxs("select", __assign({ value: selectedTest, onChange: function (e) { return setSelectedTest(e.target.value); }, className: "p-1 rounded-md", disabled: testApplied }, { children: [_jsx("option", __assign({ value: "Blood Gas Analysis" }, { children: "Blood Gas Analysis" })), _jsx("option", __assign({ value: "Glucose Levels" }, { children: "Glucose Levels" })), _jsx("option", __assign({ disabled: true, value: "Electrolytes Panel" }, { children: "Electrolytes Panel" })), _jsx("option", __assign({ disabled: true, value: "Lactate Levels" }, { children: "Lactate Levels" })), _jsx("option", __assign({ disabled: true }, { children: "Send to Lab (Complex Tests)" }))] }))] })), _jsx("div", __assign({ className: "flex justify-end items-center" }, { children: testApplied ? (_jsx("button", __assign({ className: "bg-red-500 py-1 px-4 rounded text-white", onClick: handleTestRemove }, { children: "Clear" }))) : (_jsx("button", __assign({ className: "bg-green-500 py-1 px-4 rounded text-white", onClick: handleTestApply }, { children: "Test" }))) })), testApplied && (_jsxs("div", __assign({ className: "flex flex-col gap-2" }, { children: [_jsx("label", __assign({ className: "text-white" }, { children: "Result:" })), _jsx("div", __assign({ className: "bg-white p-2 rounded-md text-black" }, { children: result }))] })))] }))] })));
};
// Helper function to generate test result
var generateTestResult = function (test) {
    switch (test) {
        case "Blood Gas Analysis":
            return "pH: 7.35, pCO₂: 40 mmHg, pO₂: 95 mmHg, HCO₃⁻: 24 mEq/L";
        case "Glucose Levels":
            return "Glucose: 110 mg/dL";
        case "Electrolytes Panel":
            return "Na⁺: 140 mEq/L, K⁺: 4.0 mEq/L, Cl⁻: 100 mEq/L";
        case "Lactate Levels":
            return "Lactate: 1.2 mmol/L";
        default:
            return "Result unavailable";
    }
};
export default BloodTestControls;
