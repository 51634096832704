import { z } from "zod";
// Blood Gas Values
var bloodGasValuesSchema = z.object({
    pH: z.number().optional().describe("pH : none"),
    pCO2: z.number().optional().describe("pCO<sub>2</sub> : mmHg"),
    pO2: z.number().optional().describe("pO<sub>2</sub> : mmHg"),
    ctCO2_Pc: z.number().optional().describe("ctCO<sub>2</sub>(P)<sub>c</sub> : mmol/L"),
});
// Oximetry Values
var oximetryValuesSchema = z.object({
    ctHb: z.number().optional().describe("ctHb : g/L"),
    Hctc: z.number().optional().describe("Hct<sub>c</sub> : %"),
    sO2: z.number().optional().describe("sO<sub>2</sub> : %"),
    FO2Hb: z.number().optional().describe("FO<sub>2</sub>Hb : %"),
    FCOHb: z.number().optional().describe("FCOHb : %"),
    FHHb: z.number().optional().describe("FHHb : %"),
    FMetHb: z.number().optional().describe("FMetHb : %"),
});
// Metabolite Values
var metaboliteValuesSchema = z.object({
    cNa: z.number().optional().describe("cNa<sup>+</sup> : mmol/L"),
    cK: z.number().optional().describe("cK<sup>+</sup> : mmol/L"),
    cCl: z.number().optional().describe("cCl<sup>-</sup> : mmol/L"),
    cCa2: z.number().optional().describe("cCa<sup>2+</sup> : mmol/L"),
    cGlu: z.number().optional().describe("cGlu : mmol/L"),
    cLac: z.number().optional().describe("cLac : mmol/L"),
    ctBil: z.number().optional().describe("ctBil : µmol/L"),
});
// Calculated Values
var calculatedValuesSchema = z.object({
    anionGap: z.number().optional().describe("Anion Gap<sub>c</sub> : mmol/L"),
    anionGap_Kc: z.number().optional().describe("Anion Gap.K<sup>+</sup><sub>c</sub> : mmol/L"),
});
// Temperature-corrected Values
var temperatureCorrectedValuesSchema = z.object({
    pH_T: z.number().optional().describe("pH(T) : none"),
    pCO2_T: z.number().optional().describe("pCO<sub>2</sub>(T) : mmHg"),
    pO2_T: z.number().optional().describe("pO<sub>2</sub>(T) : mmHg"),
});
// Oxygen Status
var oxygenStatusSchema = z.object({
    ctO2c: z.number().optional().describe("ctO<sub>2</sub><sub>c</sub> : Vol%"),
    p50c: z.number().optional().describe("p50<sub>c</sub> : mmHg"),
});
// Acid-Base Status
var acidBaseStatusSchema = z.object({
    cBase_Ecf_c: z.number().optional().describe("cBase(Ecf)<sub>c</sub> : mmol/L"),
    cHCO3_P_c: z.number().optional().describe("cHCO<sub>3</sub><sup>-</sup>(P)<sub>c</sub> : mmol/L"),
    cHCO3_Pst_c: z.number().optional().describe("cHCO<sub>3</sub><sup>-</sup>(P,st)<sub>c</sub> : mmol/L"),
});
// Additional Fields
var additionalFieldsSchema = z.object({
    baseExcess: z.number().optional().describe("Base Excess : mmol/L"),
    temperature: z.number().optional().describe("Temperature : °C"),
    lactate: z.number().optional().describe("Lactate : mmol/L"),
    glucose: z.number().optional().describe("Glucose : mmol/L"),
    oxygenSaturation: z.number().optional().describe("Oxygen Saturation : %"),
    sampleType: z.string().optional().describe("Sample Type : none"),
    sampleTime: z.string().optional().describe("Sample Time : none"),
});
// Main Schema
export var BloodGasReportSchema = z.object({
    bloodGasValues: bloodGasValuesSchema.optional(),
    oximetryValues: oximetryValuesSchema.optional(),
    metaboliteValues: metaboliteValuesSchema.optional(),
    calculatedValues: calculatedValuesSchema.optional(),
    temperatureCorrectedValues: temperatureCorrectedValuesSchema.optional(),
    oxygenStatus: oxygenStatusSchema.optional(),
    acidBaseStatus: acidBaseStatusSchema.optional(),
    additionalFields: additionalFieldsSchema.optional(),
    notes: z.array(z.string()).optional().describe("Notes: List of additional notes"),
});
export var defaultBloodGasNew = {
    bloodGasValues: {
        pH: 6.861,
        pCO2: 16.3,
        pO2: 58.8,
        ctCO2_Pc: 3.4,
    },
    oximetryValues: {
        ctHb: 172,
        Hctc: 52.8,
        sO2: 87.5,
        FO2Hb: 86.4,
        FCOHb: 0.6,
        FHHb: 12.3,
        FMetHb: 0.7,
    },
    metaboliteValues: {
        cNa: 132,
        cK: 4.9,
        cCl: 107,
        cCa2: 1.36,
        cGlu: 36,
        cLac: 1.8,
        ctBil: -9,
    },
    calculatedValues: {
        anionGap: 22.2,
        anionGap_Kc: 27.2,
    },
    temperatureCorrectedValues: {
        pH_T: 6.861,
        pCO2_T: 16.3,
        pO2_T: 58.8,
    },
    oxygenStatus: {
        ctO2c: 20.9,
        p50c: 29.57,
    },
    acidBaseStatus: {
        cBase_Ecf_c: -30.6,
        cHCO3_P_c: 2.9,
        cHCO3_Pst_c: 6.6,
    },
    additionalFields: {
        baseExcess: -30.6,
        temperature: 37.0,
    },
    notes: [
        "Base Excess out of range",
        "Ca²+(7.4) not usable",
    ],
};
