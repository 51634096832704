var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { motion } from "framer-motion";
import { useState } from "react";
import { FaArrowCircleLeft } from "react-icons/fa";
var OxygenControls = function (_a) {
    var onGoBack = _a.onGoBack;
    var _b = useState("Nasal Cannula"), selectedDevice = _b[0], setSelectedDevice = _b[1];
    var _c = useState(2), flowRate = _c[0], setFlowRate = _c[1];
    var _d = useState(24), fiO2 = _d[0], setFiO2 = _d[1]; // Initial FiO₂
    var _e = useState(false), deviceApplied = _e[0], setDeviceApplied = _e[1];
    var handleDeviceApply = function () {
        setDeviceApplied(true);
        console.log("Oxygen device applied: ".concat(selectedDevice));
    };
    var handleDeviceRemove = function () {
        setDeviceApplied(false);
        console.log("Oxygen device removed");
        // Reset flow rate and FiO₂ to default values
        setFlowRate(getFlowRateRange(selectedDevice).min);
        setFiO2(getFiO2Range(selectedDevice).min);
    };
    // Update FiO₂ when flow rate changes (optional)
    var handleFlowRateChange = function (value) {
        setFlowRate(value);
        // Optionally, calculate FiO₂ based on flow rate and device
        // setFiO2(calculateFiO2(selectedDevice, value));
    };
    return (_jsxs(motion.div, __assign({ className: "h-auto w-full flex flex-col gap-2 content-center bg-mirno-dark inline", initial: { opacity: 0 }, animate: { opacity: 1 }, exit: { opacity: 0 }, transition: { duration: 0.3 } }, { children: [_jsxs("button", __assign({ className: "text-white/50 flex items-center gap-1", onClick: onGoBack }, { children: [_jsx(FaArrowCircleLeft, {}), " Oxygen Administration"] })), _jsxs("div", __assign({ className: "flex flex-col gap-4" }, { children: [_jsxs("div", __assign({ className: "flex justify-between items-center" }, { children: [_jsx("label", __assign({ className: "text-white" }, { children: "Device:" })), _jsxs("select", __assign({ value: selectedDevice, onChange: function (e) {
                                    setSelectedDevice(e.target.value);
                                    // Reset flow rate and FiO₂ when device changes
                                    setFlowRate(getFlowRateRange(e.target.value).min);
                                    setFiO2(getFiO2Range(e.target.value).min);
                                }, className: "p-1 rounded-md", disabled: deviceApplied }, { children: [_jsx("option", __assign({ value: "Nasal Cannula" }, { children: "Nasal Cannula" })), _jsx("option", __assign({ value: "Simple Face Mask" }, { children: "Simple Face Mask" })), _jsx("option", __assign({ value: "Non-Rebreather Mask" }, { children: "Non-Rebreather Mask" })), _jsx("option", __assign({ value: "Bag-Valve Mask" }, { children: "Bag-Valve Mask" }))] }))] })), _jsx("div", __assign({ className: "flex justify-end items-center" }, { children: deviceApplied ? (_jsx("button", __assign({ className: "bg-red-500 py-1 px-4 rounded text-white", onClick: handleDeviceRemove }, { children: "Remove" }))) : (_jsx("button", __assign({ className: "bg-green-500 py-1 px-4 rounded text-white", onClick: handleDeviceApply }, { children: "Apply" }))) })), deviceApplied && (_jsxs(_Fragment, { children: [_jsxs("div", __assign({ className: "flex flex-col gap-2" }, { children: [_jsxs("label", __assign({ className: "text-white" }, { children: ["Flow Rate (L/min): ", flowRate] })), _jsx("input", { type: "range", min: getFlowRateRange(selectedDevice).min, max: getFlowRateRange(selectedDevice).max, value: flowRate, onChange: function (e) { return handleFlowRateChange(Number(e.target.value)); }, className: "w-full", onPointerMove: function (e) { e.preventDefault(); e.stopPropagation(); } })] })), _jsxs("div", __assign({ className: "flex flex-col gap-2" }, { children: [_jsxs("label", __assign({ className: "text-white" }, { children: ["FiO\u2082 (%): ", fiO2] })), _jsx("input", { type: "range", min: getFiO2Range(selectedDevice).min, max: getFiO2Range(selectedDevice).max, value: fiO2, onChange: function (e) { return setFiO2(Number(e.target.value)); }, className: "w-full", onPointerMove: function (e) { e.preventDefault(); e.stopPropagation(); } })] }))] }))] }))] })));
};
// Helper functions to get flow rate and FiO₂ ranges based on device
var getFlowRateRange = function (device) {
    switch (device) {
        case "Nasal Cannula":
            return { min: 1, max: 6 };
        case "Simple Face Mask":
            return { min: 6, max: 10 };
        case "Non-Rebreather Mask":
            return { min: 10, max: 15 };
        case "Bag-Valve Mask":
            return { min: 15, max: 25 };
        default:
            return { min: 1, max: 25 };
    }
};
var getFiO2Range = function (device) {
    switch (device) {
        case "Nasal Cannula":
            return { min: 24, max: 44 };
        case "Simple Face Mask":
            return { min: 40, max: 60 };
        case "Non-Rebreather Mask":
            return { min: 60, max: 90 };
        case "Bag-Valve Mask":
            return { min: 90, max: 100 };
        default:
            return { min: 21, max: 100 };
    }
};
export default OxygenControls;
