var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useVirtualPatientStore } from "../../../storage/VirtualPatientStore";
import { FaCircleCheck, FaCircleXmark } from "react-icons/fa6";
var PartyModeDeviceControlRow = function (_a) {
    var label = _a.label, active = _a.active, onToggle = _a.onToggle;
    return (_jsxs("button", __assign({ onClick: onToggle, className: "rounded flex gap-2 bg-white/10 items-center p-2 justify-between" }, { children: [label, active ? _jsx(FaCircleCheck, { className: "text-green-500 text-xl" }) : _jsx(FaCircleXmark, { className: "text-red-500 text-xl" })] })));
};
var PartyModeDeviceControls = function () {
    var _a = useVirtualPatientStore(), virtualPatientState = _a.virtualPatientState, updateVirtualPatientState = _a.updateVirtualPatientState;
    return (_jsxs("div", { children: [_jsx("div", __assign({ className: "flex items-center justify-between" }, { children: _jsx("h2", __assign({ className: "text-lg font-bold" }, { children: "Device Controls" })) })), _jsxs("div", __assign({ className: "mt-4 grid grid-cols-2 gap-2" }, { children: [_jsx(PartyModeDeviceControlRow, { label: "ECG", active: virtualPatientState.deviceStatus.ecg, onToggle: function () { return updateVirtualPatientState(function (state) { return state.deviceStatus.ecg = !state.deviceStatus.ecg; }); } }), _jsx(PartyModeDeviceControlRow, { label: "Pulse Ox", active: virtualPatientState.deviceStatus.pulseOximeterRightFoot, onToggle: function () { return updateVirtualPatientState(function (state) { return state.deviceStatus.pulseOximeterRightFoot = !state.deviceStatus.pulseOximeterRightFoot; }); } }), _jsx(PartyModeDeviceControlRow, { label: "IV", active: virtualPatientState.deviceStatus.ivCatheterLeftArm, onToggle: function () { return updateVirtualPatientState(function (state) { return state.deviceStatus.ivCatheterLeftArm = !state.deviceStatus.ivCatheterLeftArm; }); } }), _jsx(PartyModeDeviceControlRow, { label: "NIBP Cuff", active: virtualPatientState.deviceStatus.nibpRightArm, onToggle: function () { return updateVirtualPatientState(function (state) { return state.deviceStatus.nibpRightArm = !state.deviceStatus.nibpRightArm; }); } })] }))] }));
};
export default PartyModeDeviceControls;
