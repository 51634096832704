var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import CustomSlider from '../../../components/Reuseable/CustomSlider';
import { useVirtualPatientStore } from '../../../storage/VirtualPatientStore';
export function useExposureState() {
    var updateVirtualPatientState = useVirtualPatientStore().updateVirtualPatientState;
    function setExposureValue(key, value) {
        updateVirtualPatientState(function (state) {
            state.medicalState.exposure[key] = value;
        });
    }
    return { setExposureValue: setExposureValue };
}
export function useSkinState() {
    var updateVirtualPatientState = useVirtualPatientStore().updateVirtualPatientState;
    function setSkinValue(key, value) {
        updateVirtualPatientState(function (state) {
            state.medicalState.skin[key] = value;
        });
    }
    return { setSkinValue: setSkinValue };
}
var ExposureController = function () {
    var virtualPatientState = useVirtualPatientStore().virtualPatientState;
    var setExposureValue = useExposureState().setExposureValue;
    var setSkinValue = useSkinState().setSkinValue;
    var exposures = [
        // { label: 'Burns', value: virtualPatientState.medicalState.exposure.burns, type: 'burns' },
        // { label: 'Rashes', value: virtualPatientState.medicalState.exposure.rashes, type: 'rashes' },
        // { label: 'Wounds', value: virtualPatientState.medicalState.exposure.wounds, type: 'wounds' },
        // { label: 'Swelling', value: virtualPatientState.medicalState.exposure.swelling, type: 'swelling' },
        { label: 'Purpura', value: virtualPatientState.medicalState.exposure.purpura, type: 'purpura' },
        { label: 'Petechiae', value: virtualPatientState.medicalState.exposure.petechiae, type: 'petechiae' },
        { label: 'Urticaria', value: virtualPatientState.medicalState.exposure.urticaria, type: 'urticaria' },
    ];
    return (_jsxs("div", { children: [_jsxs("div", __assign({ id: "skin", className: "mb-4" }, { children: [_jsx("span", __assign({ className: "text-lg font-bold" }, { children: "Circulatory" })), _jsx(CustomSlider, { label: "Red Face", value: virtualPatientState.medicalState.skin.faceRedness, setValue: function (value) { return setSkinValue("faceRedness", value); } }), _jsx(CustomSlider, { label: "Sweating", value: virtualPatientState.medicalState.skin.sweaty, setValue: function (value) { return setSkinValue("sweaty", value); } }), _jsx(CustomSlider, { label: "Cyanosis", value: virtualPatientState.medicalState.skin.cyanosisFace, setValue: function (value) { return setSkinValue("cyanosisFace", value); } }), _jsx(CustomSlider, { label: "Cyanosis - Lips", value: virtualPatientState.medicalState.skin.cyanosisBody, setValue: function (value) { return setSkinValue("cyanosisBody", value); } }), _jsx(CustomSlider, { label: "Mottled Skin", value: virtualPatientState.medicalState.skin.mottled, setValue: function (value) { return setSkinValue("mottled", value); } }), _jsx(CustomSlider, { label: "Paleness", value: virtualPatientState.medicalState.skin.paleness, setValue: function (value) { return setSkinValue("paleness", value); } })] })), _jsxs("div", __assign({ id: "exposure", className: "mb-4" }, { children: [_jsx("span", __assign({ className: "text-lg font-bold" }, { children: "Other" })), exposures.map(function (exposure) { return (_jsx(CustomSlider, { label: exposure.label, value: exposure.value, setValue: function (value) { return setExposureValue(exposure.type, value); } }, exposure.type)); })] }))] }));
};
export default ExposureController;
