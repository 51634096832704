var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { DefaultVirtualPatientState } from "mirno-shared/src/DefaultState";
import Monitor from "./Monitor";
var MonitorTestIndex = function () {
    var fakeVitals = {
        heartRate: 95,
        respiratoryRate: 18,
        spo2: 89,
        nibp: { systolic: 115, diastolic: 45 },
        etco2: 35
    };
    var fakeDevices = __assign(__assign({}, DefaultVirtualPatientState.deviceStatus), { ecg: true, nibpLeftArm: true, nasalCannula: true, pulseOximeterLeftFoot: true });
    return (_jsx("div", __assign({ className: "bg-gray-900 w-full h-full p-2 flex flex-col" }, { children: _jsx(Monitor, { vitals: fakeVitals, deviceState: fakeDevices }) })));
};
export default MonitorTestIndex;
