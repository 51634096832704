var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { AppProvider } from "../../AppContext";
import ArcwareView from "../../components/Arcware/Arcware";
import { SessionProvider } from "../Sessions/SessionContext";
import { CinemedicInEngineSessionView } from "./CinemedicIndex";
var CinemedicProView = function () {
    return (_jsxs("div", __assign({ className: 'w-full h-full flex flex-col overflow-hidden bg-transparent pointer-events-none' }, { children: [_jsx("div", __assign({ id: "ue5-container", className: "flex-grow h-full w-full absolute pointer-events-auto" }, { children: _jsx(ArcwareView, {}) })), _jsx("div", __assign({ className: "absolute bottom-4 left-4 w-16 h-16 z-[9999999] pointer-events-auto" }, { children: _jsx(CinemedicInEngineSessionView, {}) }))] })));
};
var CinemedicProIndex = function () {
    return (_jsx(SessionProvider, { children: _jsx(AppProvider, { children: _jsx(CinemedicProView, {}) }) }));
};
export default CinemedicProIndex;
