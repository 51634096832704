var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useState } from 'react';
import { LineChart, Line, CartesianGrid, ResponsiveContainer, XAxis, YAxis, Tooltip } from 'recharts';
import { supraventricular_tachycardia } from './Fake_Response';
import "./Monitor.css";
import { toInteger } from 'lodash';
import { formatBloodPressure } from 'mirno-shared/src/components/BloodPressure';
function getNewCurve(waveData, bpm, displaySeconds, numBeatsInCurve, readIndex) {
    var numBeatsToDisplay = displaySeconds * bpm / 60;
    var numDataPointsToUse = numBeatsToDisplay * (waveData.length / numBeatsInCurve);
    var newCurve = [];
    for (var i = 0; i < numDataPointsToUse; i++) {
        readIndex = (readIndex + 1) % waveData.length;
        newCurve.push({ x: i, y: waveData[readIndex].y });
    }
    return { newCurve: newCurve, readIndex: readIndex };
}
var FadeClipPath = function (_a) {
    var className = _a.className, wipePosition = _a.wipePosition, wipeOut = _a.wipeOut, children = _a.children;
    if (!wipeOut) {
        return _jsx("div", __assign({ className: className, style: {
                clipPath: "polygon(0 0, ".concat(wipePosition * 100, "% 0, ").concat(wipePosition * 100, "% 100%, 0 100%)")
            } }, { children: children }));
    }
    else {
        return (_jsx("div", __assign({ className: "".concat(className, " fade-clip-path ").concat(wipeOut ? 'fade-out' : 'fade-in'), style: {
                '--wipe-start': "".concat(wipePosition * 100 + 1, "%"),
                '--wipe-end': "".concat(wipePosition * 100 + 5, "%"),
            } }, { children: children })));
    }
};
var AnimWave = function (_a) {
    var waveData = _a.waveData, bpm = _a.bpm, color = _a.color, _b = _a.numBeatsInCurve, numBeatsInCurve = _b === void 0 ? 10 : _b, _c = _a.displaySeconds, displaySeconds = _c === void 0 ? 5 : _c;
    var _d = useState({ appearingCurve: [], leavingCurve: [], readIndex: 0 }), curves = _d[0], setCurves = _d[1];
    var _e = useState(0), wipePos = _e[0], setWipePos = _e[1];
    useEffect(function () {
        setCurves(function (prevState) {
            var _a = getNewCurve(waveData, bpm, displaySeconds, numBeatsInCurve, prevState.readIndex), newCurve = _a.newCurve, readIndex = _a.readIndex;
            return {
                leavingCurve: prevState.appearingCurve,
                appearingCurve: newCurve,
                readIndex: readIndex
            };
        });
    }, [bpm, waveData, displaySeconds, numBeatsInCurve]);
    useEffect(function () {
        var refreshRate = 1 / 30;
        var interval = setInterval(function () {
            setWipePos(function (wipePos) {
                var newWipePos = (wipePos + (refreshRate / displaySeconds)) % 1;
                if (newWipePos < wipePos) {
                    setCurves(function (prevState) {
                        var _a = getNewCurve(waveData, bpm, displaySeconds, numBeatsInCurve, prevState.readIndex), newCurve = _a.newCurve, readIndex = _a.readIndex;
                        return {
                            leavingCurve: prevState.appearingCurve.map(function (point) { return (__assign({}, point)); }),
                            appearingCurve: newCurve,
                            readIndex: readIndex
                        };
                    });
                }
                return newWipePos;
            });
        }, refreshRate * 1000);
        return function () { return clearInterval(interval); };
    }, [displaySeconds, waveData, bpm, numBeatsInCurve]);
    return (_jsxs("div", __assign({ className: 'h-full relative' }, { children: [_jsx(FadeClipPath, __assign({ className: "absolute top-0 bottom-0 left-0 right-0", wipePosition: wipePos, wipeOut: false }, { children: _jsx(ResponsiveContainer, __assign({ width: "100%", height: "100%" }, { children: _jsxs(LineChart, __assign({ data: curves.appearingCurve }, { children: [_jsx(CartesianGrid, { fillOpacity: 0, opacity: 0 }), _jsx(XAxis, { dataKey: "x", hide: true }), _jsx(YAxis, { hide: true }), _jsx(Tooltip, {}), _jsx(Line, { type: "basis", dataKey: "y", stroke: color, strokeWidth: 2, dot: false, isAnimationActive: false, connectNulls: false })] })) })) })), _jsx(FadeClipPath, __assign({ className: "absolute top-0 bottom-0 left-0 right-0", wipePosition: wipePos, wipeOut: true }, { children: _jsx(ResponsiveContainer, __assign({ width: "100%", height: "100%" }, { children: _jsxs(LineChart, __assign({ data: curves.leavingCurve }, { children: [_jsx(CartesianGrid, { fillOpacity: 0, opacity: 0 }), _jsx(XAxis, { dataKey: "x", hide: true }), _jsx(YAxis, { hide: true }), _jsx(Tooltip, {}), _jsx(Line, { type: "basis", dataKey: "y", stroke: color, strokeWidth: 2, dot: false, isAnimationActive: false, connectNulls: false })] })) })) }))] })));
};
var MonitorRow = function (_a) {
    var topText = _a.topText, centerText = _a.centerText, bottomText = _a.bottomText, color = _a.color, bpm = _a.bpm, waveData = _a.waveData, numBeatsInCurve = _a.numBeatsInCurve, displaySeconds = _a.displaySeconds, showCurve = _a.showCurve;
    return (_jsxs("div", __assign({ className: "flex gap-4 h-full" }, { children: [_jsx("div", __assign({ className: "w-1/4 p-4 min-w-48 w-auto", style: { flex: "0.1", width: "auto", color: color } }, { children: _jsxs("div", __assign({ className: "flex flex-col h-full place-content-center gap-2", id: "hr" }, { children: [_jsx("div", __assign({ className: "text-lg" }, { children: topText })), _jsx("div", __assign({ className: "text-6xl" }, { children: _jsx("span", __assign({ id: "center_text" }, { children: centerText })) })), _jsx("div", __assign({ className: "text-lg" }, { children: bottomText }))] })) })), _jsx("div", __assign({ className: "flex-1 flex justify-end contents" }, { children: _jsx("div", __assign({ className: "w-full relative ".concat(showCurve ? "" : "opacity-0") }, { children: _jsx(AnimWave, { waveData: waveData, bpm: bpm, color: color, numBeatsInCurve: numBeatsInCurve, displaySeconds: displaySeconds }) })) }))] })));
};
var DATA_SOURCE = supraventricular_tachycardia;
var Monitor = function (_a) {
    var vitals = _a.vitals, deviceState = _a.deviceState;
    var waveData = {
        ecg: DATA_SOURCE.wave_ecg.slice(0, 160),
        spo2: DATA_SOURCE.wave_pleth.slice(0, 100),
        nibp: DATA_SOURCE.wave_nibp.slice(0, 100),
        etco2: DATA_SOURCE.wave_etco2.slice(0, 12),
    };
    function MAP() {
        if (!vitals.nibp)
            return "-";
        var sbp = vitals.nibp.systolic;
        var dbp = vitals.nibp.diastolic;
        var vs_map = toInteger((sbp - dbp) / 3 + dbp);
        return vs_map.toString();
    }
    function str(value) {
        if (!value)
            return "-";
        return value.toString();
    }
    function bpstr(value) {
        if (!value)
            return "-/-";
        return formatBloodPressure(value);
    }
    function ECGCenterText() {
        if (deviceState.ecg) {
            return str(vitals.heartRate);
        }
        else {
            return "---";
        }
    }
    function spo2CenterText() {
        if (deviceState.pulseOximeterLeftFoot || deviceState.pulseOximeterRightFoot) {
            return str(vitals.spo2);
        }
        else {
            return "---";
        }
    }
    function nibpCenterText() {
        if (deviceState.nibpLeftArm || deviceState.nibpRightArm) {
            return bpstr(vitals.nibp);
        }
        else {
            return "-/-";
        }
    }
    function nibpBottomText() {
        if (deviceState.nibpLeftArm || deviceState.nibpRightArm) {
            return "(MAP = " + MAP() + ")";
        }
        else {
            return "";
        }
    }
    function etco2CenterText() {
        if (deviceState.ecg) {
            return str(vitals.respiratoryRate);
        }
        else {
            return "--";
        }
    }
    function etco2BottomText() {
        if (deviceState.nasalCannula) {
            return "ETCO2 mmHg " + str(vitals.etco2);
        }
        else {
            return "ETCO2 mmHg ---";
        }
    }
    return (_jsxs("div", __assign({ className: "flex flex-col gap-4 bg-gray-900 text-nowrap h-full" }, { children: [_jsx(MonitorRow, { topText: "ECG bpm", centerText: ECGCenterText(), bottomText: "Lead II", color: "#0dff45", bpm: vitals.heartRate, waveData: waveData.ecg, numBeatsInCurve: 10, displaySeconds: 5, showCurve: deviceState.ecg }), _jsx(MonitorRow, { topText: "SpO2 %", centerText: spo2CenterText(), bottomText: "", color: "#56dbfc", bpm: vitals.heartRate, waveData: waveData.spo2, numBeatsInCurve: 10, displaySeconds: 5, showCurve: deviceState.pulseOximeterLeftFoot || deviceState.pulseOximeterRightFoot }), _jsx(MonitorRow, { topText: "RR bpm", centerText: etco2CenterText(), bottomText: etco2BottomText(), color: "white", bpm: vitals.respiratoryRate / 2, waveData: waveData.etco2, numBeatsInCurve: 1, displaySeconds: 10, showCurve: deviceState.ecg }), _jsx(MonitorRow, { topText: "BP mmHg", centerText: nibpCenterText(), bottomText: nibpBottomText(), color: "#fc5ded", bpm: vitals.heartRate, waveData: waveData.nibp, numBeatsInCurve: 10, displaySeconds: 5, showCurve: false })] })));
};
export default Monitor;
