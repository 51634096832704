var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useCallback, useEffect, useState } from 'react';
import "./styles.css";
import ArcwareView from '../../components/Arcware/Arcware';
import PaigenChatManager from 'mirno-shared/src/paigen/PaigenChatManager';
import { observer } from 'mobx-react-lite';
import PaigenChatComponent from './Chat/PaigenChatComponent';
import SymptomControlDisplay from './SymptomControls/SymptomControlDisplay';
import { confirmDiscardChanges, useAppContext } from '../../AppContext';
import SaveModal from './SaveModal';
import { Menu, MenuButton } from '@headlessui/react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { DefaultVirtualPatientState } from 'mirno-shared/src/DefaultState';
import VirtualPatientStateStorage from '../../components/Database/VirtualPatientStateStorage';
import LoadModal from './LoadModal';
import { useVirtualPatientStore } from '../../storage/VirtualPatientStore';
import { toJS } from 'mobx';
export var useWIPPatientEffect = function (paigenChatManager) {
    var setVirtualPatientState = useVirtualPatientStore().setVirtualPatientState;
    React.useEffect(function () {
        if (!paigenChatManager.generatedPatient) {
            return;
        }
        setVirtualPatientState(toJS(paigenChatManager.generatedPatient));
    }, [paigenChatManager.generatedPatient, setVirtualPatientState]);
};
var SaveFunctionImplementor = /** @class */ (function () {
    function SaveFunctionImplementor(saveCallback) {
        var _this = this;
        this.toolDefinition = {
            definition: {
                name: "save",
                description: "Opens the save window for the user",
                parameters: {}
            },
            implementation: function (args) { return __awaiter(_this, void 0, Promise, function () {
                return __generator(this, function (_a) {
                    this.saveCallback();
                    return [2 /*return*/, "Save window opened."];
                });
            }); }
        };
        this.saveCallback = saveCallback;
    }
    return SaveFunctionImplementor;
}());
var PaigenIndex = observer(function () {
    var saveImplementor = new SaveFunctionImplementor(savePatient);
    var location = useLocation();
    var locationState = React.useState(location.state)[0];
    var isModified = useAppContext().isModified;
    var setVirtualPatientState = useVirtualPatientStore().setVirtualPatientState;
    var paigenChatManager = useState(new PaigenChatManager(undefined, [saveImplementor.toolDefinition]))[0];
    var _a = useState(false), saveWindowOpen = _a[0], setSaveWindowOpen = _a[1];
    var _b = useState(false), loadWindowOpen = _b[0], setLoadWindowOpen = _b[1];
    var navigate = useNavigate();
    var urlParam_PresetId = useParams().presetId;
    var _c = useState(urlParam_PresetId), presetId = _c[0], setPresetId = _c[1];
    useEffect(function () {
        if (locationState && locationState.maiaMessage && paigenChatManager && paigenChatManager.messages.length === 0) {
            paigenChatManager.addUserMessage(locationState.maiaMessage);
            paigenChatManager.processMessages();
        }
    }, [locationState, paigenChatManager]);
    useEffect(function () {
        document.title = "Mirno - Maia";
    });
    var loadPresetFromId = useCallback(function (presetId) {
        VirtualPatientStateStorage.getPreset(presetId).then(function (preset) {
            if (preset) {
                console.log("Preset loaded: ", preset);
                setVirtualPatientState(preset.virtualPatientState);
                paigenChatManager.loaded(preset.virtualPatientState);
            }
            else {
                console.error("Preset not found:", presetId);
            }
        });
        navigate("/maia/".concat(presetId));
    }, [paigenChatManager, setVirtualPatientState, navigate]);
    var resetToDefault = useCallback(function () {
        setVirtualPatientState(DefaultVirtualPatientState);
        paigenChatManager.reset(DefaultVirtualPatientState);
        navigate("/maia");
    }, [setVirtualPatientState, paigenChatManager, navigate]);
    useEffect(function () {
        if (presetId) {
            loadPresetFromId(presetId);
        }
    }, [presetId, loadPresetFromId, resetToDefault]);
    useWIPPatientEffect(paigenChatManager);
    function savePatient() {
        setSaveWindowOpen(true);
    }
    function newPatient() {
        if (isModified() && !confirmDiscardChanges())
            return;
        setPresetId(undefined);
        resetToDefault();
    }
    // function advancedEditor() {
    //     navigate('/editor');
    // }
    function presetSelected(doc) {
        setPresetId(doc.id);
        setLoadWindowOpen(false);
    }
    return (_jsx(_Fragment, { children: _jsxs("div", __assign({ className: 'flex h-full w-full overflow-hidden' }, { children: [_jsxs("div", __assign({ className: "h-full overflow-hidden flex flex-col text-xs bg-mirno-dark w-[32rem]" }, { children: [_jsx("div", __assign({ className: 'flex gap-2 p-1' }, { children: _jsxs(Menu, { children: [_jsx(MenuButton, __assign({ className: 'text-mirno-light font-semibold p-2', onClick: newPatient }, { children: "New" })), _jsx(MenuButton, __assign({ className: 'text-mirno-light font-semibold p-2', onClick: function () { setLoadWindowOpen(true); } }, { children: "Load" })), _jsx(MenuButton, __assign({ className: 'text-mirno-light font-semibold p-2', onClick: savePatient }, { children: "Save" }))] }) })), _jsx(PaigenChatComponent, { paigenChatManager: paigenChatManager })] })), _jsx("div", __assign({ className: 'w-full h-full overflow-hidden flex flex-col text-xs' }, { children: _jsxs("div", __assign({ className: 'flex-auto h-full relative' }, { children: [_jsx("div", __assign({ className: 'absolute top-0 z-[999999] w-full h-full overflow-hidden pointer-events-none' }, { children: _jsx(SymptomControlDisplay, {}) })), _jsx(ArcwareView, {})] })) })), _jsx(SaveModal, { open: saveWindowOpen, onClose: function () { setSaveWindowOpen(false); } }), _jsx(LoadModal, { open: loadWindowOpen, onClose: function () { setLoadWindowOpen(false); }, onSelection: presetSelected })] })) }));
});
export default PaigenIndex;
