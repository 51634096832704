var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { PatientType } from "mirno-shared/src/PatientState";
import React from "react";
import { useVirtualPatientStore } from "../../../storage/VirtualPatientStore";
var AvailablePatientTypes = [PatientType.female1yo, PatientType.female44yo];
var PartyModePatientSelector = function () {
    var _a = useVirtualPatientStore(), virtualPatientState = _a.virtualPatientState, updateVirtualPatientState = _a.updateVirtualPatientState;
    function onPatientTypeChanged(newPatientType) {
        updateVirtualPatientState(function (state) {
            state.staticData.patientProfile.patientType = newPatientType;
        });
    }
    return (_jsxs("div", __assign({ className: "w-full flex" }, { children: [_jsx("label", __assign({ className: "w-48" }, { children: "Patient Type" })), _jsx("select", __assign({ value: virtualPatientState.staticData.patientProfile.patientType, onChange: function (e) { return onPatientTypeChanged(e.target.value); }, className: "ml-2 py-1 px-2 border border-gray-300 bg-white rounded-md shadow-sm text-black" }, { children: AvailablePatientTypes.map(function (patientType, id) { return (_jsx("option", __assign({ value: patientType }, { children: patientType }), id)); }) }))] })));
};
export default PartyModePatientSelector;
