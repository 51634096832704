var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { AnimatePresence, motion } from "framer-motion";
// import "./CrashCartMenu.css";
import { useState } from "react";
import { FaArrowCircleLeft, FaArrowCircleRight } from "react-icons/fa";
import DefibControls from "./Controls/DefibControls";
import OxygenControls from "./Controls/OxygenControls";
import FluidControls from "./Controls/FluidControls";
import MedicationControls from "./Controls/MedicationControls";
import IVAccessControls from "./Controls/IVAccessControls";
import BloodTestControls from "./Controls/BloodTestControls";
export var CrashCartButtonClassName = "bg-white/10 rounded p-1";
var CrashCartSubMenu;
(function (CrashCartSubMenu) {
    CrashCartSubMenu["CRITICAL"] = "critical";
    CrashCartSubMenu["MEDICATIONS"] = "medications";
    CrashCartSubMenu["AIRWAY"] = "airway";
    CrashCartSubMenu["IV_ACCESS"] = "iv_access";
    CrashCartSubMenu["FLUIDS"] = "fluids";
    CrashCartSubMenu["MONITORING"] = "monitoring";
    CrashCartSubMenu["TESTS_REPORTS"] = "tests_reports";
})(CrashCartSubMenu || (CrashCartSubMenu = {}));
var CrashCartMenu = function (props) {
    var _a = useState(null), currentMenu = _a[0], setCurrentMenu = _a[1];
    function setMenu(menu) {
        setCurrentMenu(menu);
    }
    return (_jsx(motion.div, __assign({ className: "h-full w-full overflow-hidden" }, { children: _jsxs(AnimatePresence, { children: [currentMenu === null && (_jsx(CrashCartMainMenu, { setMenu: setMenu })), currentMenu === CrashCartSubMenu.CRITICAL && (_jsx(CriticalMenu, { onGoBack: function () { return setMenu(null); } })), currentMenu === CrashCartSubMenu.MEDICATIONS && (
                // <MeducationsMenu onGoBack={() => setMenu(null)} />
                _jsx(MedicationControls, { onGoBack: function () { return setMenu(null); }, onMedicationPrepared: props.onMedicationPrepared, onMedicationCanceled: props.onMedicationCanceled })), currentMenu === CrashCartSubMenu.AIRWAY && (_jsx(AirwayMenu, { onGoBack: function () { return setMenu(null); } })), currentMenu === CrashCartSubMenu.IV_ACCESS && (
                // <IVAccessMenu onGoBack={() => setMenu(null)} />
                _jsx(IVAccessControls, { onGoBack: function () { return setMenu(null); }, onIVApplied: props.onIVApplied, onIVRemoved: props.onIVRemoved })), currentMenu === CrashCartSubMenu.FLUIDS && (
                // <FluidsMenu onGoBack={() => setMenu(null)} />
                _jsx(FluidControls, { onGoBack: function () { return setMenu(null); } })), currentMenu === CrashCartSubMenu.MONITORING && (_jsx(MonitoringMenu, { onGoBack: function () { return setMenu(null); }, onOptionSelected: props.onMonitorOptionSelected })), currentMenu === CrashCartSubMenu.TESTS_REPORTS && (_jsx(TestsReportsMenu, { onGoBack: function () { return setMenu(null); } }))] }) })));
};
var CrashCartMainMenu = function (_a) {
    var setMenu = _a.setMenu;
    var buttonClassName = "bg-white/10 rounded p-1";
    return (_jsxs(motion.div, __assign({ className: "h-auto w-full flex flex-col gap-1 content-center inline", initial: { opacity: 0 }, animate: { opacity: 1 }, exit: { opacity: 0 }, transition: { duration: 0.3 } }, { children: [_jsxs("button", __assign({ onClick: function () { return setMenu(CrashCartSubMenu.MEDICATIONS); }, className: buttonClassName }, { children: ["Medications ", _jsx(FaArrowCircleRight, {})] })), _jsxs("button", __assign({ onClick: function () { return setMenu(CrashCartSubMenu.IV_ACCESS); }, className: buttonClassName }, { children: ["IV Access ", _jsx(FaArrowCircleRight, {})] })), _jsxs("button", __assign({ onClick: function () { return setMenu(CrashCartSubMenu.MONITORING); }, className: buttonClassName }, { children: ["Monitoring ", _jsx(FaArrowCircleRight, {})] })), _jsxs("button", __assign({ onClick: function () { return setMenu(CrashCartSubMenu.TESTS_REPORTS); }, className: buttonClassName }, { children: ["Tests & Reports ", _jsx(FaArrowCircleRight, {})] }))] })));
};
var CrashCartMenuThing = function (_a) {
    var onGoBack = _a.onGoBack, title = _a.title, options = _a.options, onSelectOption = _a.onSelectOption;
    return (_jsxs(motion.div, __assign({ className: "h-auto w-full flex flex-col gap-1 content-center bg-mirno-dark inline", initial: { opacity: 0 }, animate: { opacity: 1 }, exit: { opacity: 0 }, transition: { duration: 0.3 } }, { children: [_jsxs("button", __assign({ className: "text-white/50", onClick: onGoBack }, { children: [_jsx(FaArrowCircleLeft, {}), " ", title] })), options.map(function (option, id) { return (_jsxs("button", __assign({ onClick: function () { return onSelectOption(option); }, className: CrashCartButtonClassName }, { children: [option, " ", _jsx(FaArrowCircleRight, {})] }), id)); })] })));
};
var CriticalMenu = function (_a) {
    var onGoBack = _a.onGoBack;
    var _b = useState(null), currentOption = _b[0], setCurrentOption = _b[1];
    var stateMap = {
        "Defibrillation": _jsx(DefibControls, { onGoBack: function () { return setCurrentOption(null); } }),
        "Oxygen": _jsx(OxygenControls, { onGoBack: function () { return setCurrentOption(null); } })
    };
    return (_jsx(_Fragment, { children: currentOption === null ? (_jsx(CrashCartMenuThing, { onGoBack: onGoBack, title: "Defib & Oxygen", options: ["Defibrillation", "Oxygen"], onSelectOption: setCurrentOption })) : (stateMap[currentOption]) }));
};
var AirwayMenu = function (_a) {
    var onGoBack = _a.onGoBack;
    return (_jsx(CrashCartMenuThing, { onGoBack: onGoBack, title: "Airway Management", options: ["Manipulation", "Suction", "Intubation"], onSelectOption: function (option) { return console.log(option); } }));
};
var MonitoringMenu = function (_a) {
    var onGoBack = _a.onGoBack, onOptionSelected = _a.onOptionSelected;
    return (_jsx(CrashCartMenuThing, { onGoBack: onGoBack, title: "Monitoring", options: ["ECG", "Pulse Oximeter", "NIBP"], onSelectOption: onOptionSelected }));
};
var TestsReportsMenu = function (_a) {
    var onGoBack = _a.onGoBack;
    var _b = useState(null), currentOption = _b[0], setCurrentOption = _b[1];
    var stateMap = {
        "Blood": _jsx(BloodTestControls, { onGoBack: function () { return setCurrentOption(null); } }),
        // "Imaging": null
    };
    return (_jsx(_Fragment, { children: currentOption === null ? (_jsx(CrashCartMenuThing, { onGoBack: onGoBack, title: "Tests & Reports", options: ["Blood"], onSelectOption: setCurrentOption })) : (stateMap[currentOption]) }));
};
export default CrashCartMenu;
