var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { createElement as _createElement } from "react";
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import './CustomSlider.css';
import React from 'react';
import ReactSlider from 'react-slider';
var CustomSlider = function (_a) {
    var label = _a.label, value = _a.value, setValue = _a.setValue, _b = _a.vertical, vertical = _b === void 0 ? false : _b;
    return (_jsxs("div", __assign({ className: "flex items-center w-full mt-2 mb-2 h-6 ".concat(vertical ? 'flex-col' : '') }, { children: [_jsx("label", __assign({ htmlFor: "".concat(label, "Slider"), className: "select-none ".concat(vertical ? '' : 'w-64') }, { children: label })), _jsx(ReactSlider, { className: "custom-slider", thumbClassName: "custom-thumb", trackClassName: "custom-track", onChange: function (value) { return setValue(value / 100); }, value: value * 100, renderThumb: function (props, state) {
                    var key = props.key;
                    delete props.key;
                    return (_createElement("div", __assign({}, props, { key: key, className: 'w-6 h-6 rounded-full bg-[#7F56D9]' })));
                } })] })));
};
export default CustomSlider;
