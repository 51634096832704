// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { initializeFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";


// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAlLz0JrCWd-oD1kGnqwBho6W320GlgeJ4",
  authDomain: "patient-viewer.firebaseapp.com",
  projectId: "patient-viewer",
  storageBucket: "patient-viewer.appspot.com",
  messagingSenderId: "234546430577",
  appId: "1:234546430577:web:e492183062904d92ded1d0",
  measurementId: "G-M6WFB4095G"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
export const firestore = initializeFirestore(app, {
  ignoreUndefinedProperties: true
});
export const storage = getStorage(app);