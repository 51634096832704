var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import OpenAI from 'openai';
import { zodResponseFormat } from 'openai/helpers/zod';
import React, { useState } from 'react';
import { ClipLoader } from 'react-spinners';
import { z } from 'zod';
import { uploadImages } from './ImageUploader';
import { useNavigate } from 'react-router-dom';
var ImageUploader = function (_a) {
    var onSubmit = _a.onSubmit;
    var _b = useState([]), images = _b[0], setImages = _b[1];
    var _c = useState(false), isDraggingOver = _c[0], setIsDraggingOver = _c[1];
    var handleImageChange = function (e) {
        if (e.target.files) {
            var filesArray_1 = Array.from(e.target.files);
            setImages(function (prevImages) { return prevImages.concat(filesArray_1); });
        }
    };
    var handleDeleteImage = function (index) {
        setImages(function (prevImages) { return prevImages.filter(function (_, i) { return i !== index; }); });
    };
    var handleSubmit = function () {
        // Convert images to base64
        var promises = images.map(function (image) {
            return new Promise(function (resolve, reject) {
                var reader = new FileReader();
                reader.readAsDataURL(image);
                reader.onload = function () { return resolve(reader.result); };
                reader.onerror = function (error) { return reject(error); };
            });
        });
        Promise.all(promises)
            .then(function (base64Images) {
            onSubmit(base64Images, images);
        })
            .catch(function (error) {
            console.error('Error reading files:', error);
        });
    };
    // Drag-and-Drop Handlers
    var handleDragOver = function (e) {
        e.preventDefault();
        e.stopPropagation();
        setIsDraggingOver(true);
    };
    var handleDragLeave = function (e) {
        e.preventDefault();
        e.stopPropagation();
        setIsDraggingOver(false);
    };
    var handleDrop = function (e) {
        e.preventDefault();
        e.stopPropagation();
        setIsDraggingOver(false);
        if (e.dataTransfer.files) {
            var filesArray = Array.from(e.dataTransfer.files);
            // Filter out non-image files
            var imageFiles_1 = filesArray.filter(function (file) {
                return file.type.startsWith('image/');
            });
            setImages(function (prevImages) { return prevImages.concat(imageFiles_1); });
        }
    };
    return (_jsxs("div", __assign({ className: "p-6 font-sans max-w-md mx-auto" }, { children: [_jsx("h2", __assign({ className: "text-2xl font-semibold mb-4 text-center" }, { children: "Scan Scenario Script" })), _jsxs("label", __assign({ htmlFor: "image-upload", className: "flex flex-col items-center justify-center w-full h-48 border-2 ".concat(isDraggingOver ? 'border-blue-500' : 'border-gray-300', " border-dashed rounded-lg cursor-pointer hover:border-blue-500 mb-6"), onDragOver: handleDragOver, onDragLeave: handleDragLeave, onDrop: handleDrop }, { children: [_jsxs("div", __assign({ className: "flex flex-col items-center justify-center pt-5 pb-6" }, { children: [_jsxs("svg", __assign({ className: "w-12 h-12 mb-3 text-gray-400", fill: "currentColor", viewBox: "0 0 20 20" }, { children: [_jsx("path", { d: "M4 3a2 2 0 012-2h8a2 2 0 012 2v3h-2V3H6v14h6v-3h2v3a2 2 0 01-2 2H6a2 2 0 01-2-2V3z" }), _jsx("path", { d: "M14 14l5-5m0 0l-5-5m5 5H9" })] })), _jsxs("p", __assign({ className: "mb-2 text-sm text-gray-500" }, { children: [_jsx("span", __assign({ className: "font-semibold" }, { children: "Click to upload" })), " or drag and drop files"] })), _jsx("p", __assign({ className: "text-xs text-gray-500" }, { children: "PNG, JPG up to 5MB" }))] })), _jsx("input", { id: "image-upload", type: "file", accept: "image/*", capture: "environment", onChange: handleImageChange, multiple: true, className: "hidden" })] })), images.length > 0 && (_jsx("div", __assign({ className: "grid grid-cols-3 gap-4 mb-6" }, { children: images.map(function (image, index) {
                    var imageUrl = URL.createObjectURL(image);
                    return (_jsxs("div", __assign({ className: "relative" }, { children: [_jsx("img", { src: imageUrl, alt: "Uploaded ".concat(index), className: "w-full h-24 object-cover rounded-md" }), _jsx("button", __assign({ onClick: function () { return handleDeleteImage(index); }, className: "absolute top-1 right-1 bg-red-600 text-white rounded-full w-6 h-6 flex items-center justify-center text-xs", "aria-label": "Delete image" }, { children: "\u00D7" }))] }), index));
                }) }))), _jsx("button", __assign({ onClick: handleSubmit, disabled: images.length === 0, className: "w-full py-3 text-white font-semibold rounded-lg transition-colors text-center block ".concat(images.length > 0
                    ? 'bg-blue-600 hover:bg-blue-700'
                    : 'bg-gray-400 cursor-not-allowed') }, { children: "Create Virtual Patient" }))] })));
};
var openai = new OpenAI({
    apiKey: process.env.REACT_APP_OPENAI_API_KEY,
    dangerouslyAllowBrowser: true,
});
var ScenarioInfoSchema = z.object({
    medicalSummary: z.string().describe("Summary of the medical info of the scenario."),
    presentingIllness: z.string().describe("The presenting illness."),
    age: z.string().describe("Eg. '3 years', '18 weeks'").optional(),
    weight: z.number().describe("In kilograms").optional(),
    height: z.number().describe("In centimeters").optional(),
    initialState: z.object({
        heartRate: z.number().describe("Beats per minute").optional(),
        respiratoryRate: z.number().describe("Breaths per minute").optional(),
        bloodPressure: z.object({
            systolic: z.number().describe("mmhg"),
            diastolic: z.number().describe("mmhg")
        }).optional(),
        o2saturation: z.number().describe("%").optional(),
        temperature: z.number().describe("degrees C").optional(),
        capRefill: z.number().describe("seconds").optional(),
        avpu: z.enum(["A", "V", "P", "U"]).describe("consciousness").optional()
    }),
    medicalStateChanges: z.array(z.string()).describe("A list of conditional medical state changes, in English, in the format 'if [condition] -> [change]'").optional(),
});
var ScriptPilot = function () {
    var _a = useState(null), workingText = _a[0], setWorkingText = _a[1];
    var _b = useState(null), parsedData = _b[0], setParsedData = _b[1];
    var navigate = useNavigate();
    function handleImagesSubmit(base64Images, imageFiles) {
        return __awaiter(this, void 0, void 0, function () {
            var _a, uuid, imageURLs, image_content, responseFormat, response, scenarioInfo, bloodPressureText, scenarioDescriptionForMaia;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        // Do something with the base64 images
                        setWorkingText("Uploading images...");
                        return [4 /*yield*/, uploadImages(imageFiles)];
                    case 1:
                        _a = _b.sent(), uuid = _a.uuid, imageURLs = _a.imageURLs;
                        console.log(imageURLs);
                        image_content = imageURLs.map(function (url) { return ({
                            type: "image_url",
                            image_url: { url: url }
                        }); });
                        responseFormat = zodResponseFormat(ScenarioInfoSchema, "ScenarioData");
                        responseFormat.json_schema.strict = false;
                        setWorkingText("Processing images...");
                        return [4 /*yield*/, openai.beta.chat.completions.parse({
                                model: 'gpt-4o',
                                response_format: responseFormat,
                                temperature: 0,
                                messages: [
                                    {
                                        role: "system",
                                        content: "Extract the relevant data about the simulation training script from the user's input, giving the results as a json object. If a piece of information about the patient state is missing, skip the field from the object."
                                    },
                                    {
                                        role: "user",
                                        content: __spreadArray([], image_content, true)
                                    }
                                ]
                            })];
                    case 2:
                        response = _b.sent();
                        console.log(response.choices[0].message.parsed);
                        scenarioInfo = response.choices[0].message.parsed;
                        setParsedData({
                            scenarioInfo: scenarioInfo,
                            imageStorageUUID: uuid,
                            imageUrls: imageURLs
                        });
                        setWorkingText(null);
                        bloodPressureText = scenarioInfo.initialState.bloodPressure ? "".concat(scenarioInfo.initialState.bloodPressure.systolic, "/").concat(scenarioInfo.initialState.bloodPressure.diastolic, " mmHg") : "?/?";
                        scenarioDescriptionForMaia = "\n# Scenario Description\n".concat(scenarioInfo.medicalSummary, "\n").concat(scenarioInfo.presentingIllness, "\n\n# Patient Info\n- Age: ").concat(scenarioInfo.age || "?", "\n- Weight: ").concat(scenarioInfo.weight || "?", " kg\n- Height: ").concat(scenarioInfo.height || "?", " cm\n\n# Condition\n- Heart Rate: ").concat(scenarioInfo.initialState.heartRate || "?", " bpm\n- Respiratory Rate: ").concat(scenarioInfo.initialState.respiratoryRate || "?", " bpm\n- Blood Pressure: ").concat(bloodPressureText, "\n- O2 Saturation: ").concat(scenarioInfo.initialState.o2saturation || "?", "%\n- Temperature: ").concat(scenarioInfo.initialState.temperature || "?", "\u00B0C\n- Cap Refill: ").concat(scenarioInfo.initialState.capRefill || "?", " seconds\n- AVPU: ").concat(scenarioInfo.initialState.avpu || "?", "\n");
                        navigate('/maia', { state: { maiaMessage: scenarioDescriptionForMaia } });
                        return [2 /*return*/];
                }
            });
        });
    }
    ;
    return (_jsx("div", __assign({ className: "w-full h-full" }, { children: workingText !== null ? (_jsxs("div", __assign({ className: "w-full h-full flex flex-col justify-center mt-5 items-center content-center center" }, { children: [_jsx("h2", __assign({ className: "text-2xl font-semibold mb-4 text-center" }, { children: "Working" })), _jsx(ClipLoader, { color: "#4A90E2", size: 50 }), workingText] }))) : (_jsx(_Fragment, { children: parsedData !== null ? (_jsxs("div", __assign({ className: 'w-full h-full' }, { children: [_jsx("pre", __assign({ className: 'w-full text-wrap text-xs' }, { children: JSON.stringify(parsedData, null, 4) })), _jsx("button", __assign({ onClick: function () { return setParsedData(null); }, className: "bg-blue-500 text-white px-4 py-2 rounded-lg text-center block" }, { children: "Back" }))] }))) : (_jsx(ImageUploader, { onSubmit: handleImagesSubmit })) })) })));
};
export default ScriptPilot;
