export var LungSound;
(function (LungSound) {
    LungSound["Normal"] = "Normal";
    LungSound["Bronchial"] = "Bronchial";
    LungSound["Bronchovesicular"] = "Bronchovesicular";
    LungSound["CoarseCrackles"] = "CoarseCrackles";
    LungSound["FineCrackles"] = "FineCrackles";
    LungSound["LowPitchWheeze"] = "LowPitchWheeze";
    LungSound["PleuralRubs"] = "PleuralRubs";
    LungSound["Wheeze"] = "Wheeze";
})(LungSound || (LungSound = {}));
export var BreathingSoundMetadata = {
    normal: {
        unit: "intensity, 0 - 1"
    },
    grunting: {
        unit: "intensity, 0 - 1"
    },
    gurgling: {
        unit: "intensity, 0 - 1"
    },
    stridor: {
        unit: "intensity, 0 - 1"
    },
    wheezing: {
        unit: "intensity, 0 - 1"
    },
    snoring: {
        unit: "intensity, 0 - 1"
    },
    leftLungSound: {
        description: "When auscultated",
        options: Object.keys(LungSound)
    },
    rightLungSound: {
        description: "When auscultated",
        options: Object.keys(LungSound)
    }
};
export var WorkOfBreathingMetadata = {
    headBobbing: {
        unit: "intensity, 0 - 1"
    },
    nasalFlaring: {
        unit: "intensity, 0 - 1"
    },
    retractions: {
        unit: "intensity, 0 - 1"
    },
    sterno: {
        unit: "intensity, 0 - 1"
    }
};
